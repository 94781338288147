import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { jsonEqual } from '../../../lib/utils';
import { getDatetimeUtc, getTimeDifference } from '../../../lib/dateTimeUtils';
import './StandbyGroundPuck.css';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { AirlineFilter, Treatment } from '../../../lib/constants';
import { useFocusedFlightLegDispatch } from '../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import FlightActionMenu from '../FlightActionMenu/FlightActionMenu';
import { getFlightPuckClassNames } from '../GanttChart/ganttHelpers';
import { useFlightNumberOverflow } from '../../../hooks/useGanttConfig/utils/utils';
import { getAirlineName } from '../../../lib/displayUtils';

/**
 * Renders a standby Ground Puck using the given ground puck data and positions the puck
 * with respect to the gantt start time.
 *
 * A ground puck be a station label to represent turn time between flights,
 * extract out standby ground events into its own component
 * @param {Object} props
 * @param {Object} props.data - Ground puck data to be rendered
 * @returns - A StandbyGroundPuck component
 */
const StandbyGroundPuck = React.memo(
  ({
    data,
    getFlightLineForFlightPuck,
    airline,
    openDetailPane,
    isPaneOpen,
    summaryPanelMode,
    handleChangeActivityKey,
  }) => {
    // calculate the width
    const arrival = getDatetimeUtc(data.arrival);
    const departure = data.departure != null ? getDatetimeUtc(data.departure) : null;
    const duration = departure != null ? getTimeDifference(arrival, departure, 'minutes') : 0;
    const selectedFlightDetails = useSelectedItemStore();
    const { updateSelectedFlightDetails, useIsSelected } = useSelectedItemDispatch();
    const flightNumberRef = useRef(null);

    const { clearFocusedFlightLeg } = useFocusedFlightLegDispatch();
    const { showFlightNumber } = useFlightNumberOverflow(flightNumberRef);

    const { showFeature } = useFeatureFlag();

    const isSummaryPanelAvailable = showFeature(Treatment.FLIGHT_DETAIL_SUMMARY);
    const melcdlFlag = showFeature(Treatment.FLIGHT_MELDATA);

    const [showFlightActionMenu, setShowFlightActionMenu] = useState(false);

    const [showOverLay, setShowOverLay] = useState(false);

    // set width and textAlign styles to puck
    const puckWidthStyle = {
      width: duration > 0 ? `calc(${duration} * var(--time-scale-minute))` : null,
    };

    const shortPuckFlag = !showFlightNumber ? 'short-puck-flag' : '';

    const handleClick = async () => {
      clearFocusedFlightLeg();
      // Update the selected flight details if previously selected flight is different from the clicked flight
      if (isSummaryPanelAvailable && selectedFlightDetails.data != data) {
        const newFlightDetails = {
          data: data,
          isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
          isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
        };
        updateSelectedFlightDetails(newFlightDetails);
      }
    };

    const handleMouseEnter = () => {
      setShowFlightActionMenu(true);
    };

    const flightActionMenuContainer = (
      <div className={`flight-action-menu ${shortPuckFlag}`}>
        <FlightActionMenu
          flightLeg={data}
          openDetailPane={openDetailPane}
          isPaneOpen={isPaneOpen}
          summaryPanelMode={summaryPanelMode}
          handleChangeActivityKey={handleChangeActivityKey}
          overLayHandler={(e) => setShowOverLay(e)}
          getFlightLineForFlightPuck={getFlightLineForFlightPuck}
          puckType={data.puckType}
        />
      </div>
    );

    const isSelected = useIsSelected(data, 'standby');
    const selectLabel = isSelected ? 'selected-puck' : '';

    // retune the standby aircraft puck lable
    return (
      <div
        className={`flightpuck-container ${selectLabel} ${shortPuckFlag} ${showOverLay ? 'visible-overlay' : ''}`}
        data-cy="flightpuck-container"
      >
        <div
          className={getFlightPuckClassNames(
            ['ground-standby-puck'],
            data,
            isSelected,
            isPaneOpen,
            false,
            false,
            false,
          )}
          style={puckWidthStyle}
          data-cy={`ground-standby-puck-${data.aircraft}-${data.departureStation}-${data.departure}`}
          onMouseEnter={handleMouseEnter}
          onClick={handleClick}
        >
          {'Standby Aircraft: ' + (data.arrivalStation ?? '')}
          {melcdlFlag && airline !== getAirlineName(AirlineFilter.OO) && (
            <span className="standby-access-menu">{showFlightActionMenu && flightActionMenuContainer}</span>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return jsonEqual(prevProps.data, nextProps.data);
  },
);

StandbyGroundPuck.propTypes = {
  data: PropTypes.shape({
    arrivalStation: PropTypes.string,
    arrival: PropTypes.string,
    departureStation: PropTypes.string,
    departure: PropTypes.string,
    aircraft: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }),
  openDetailPane: PropTypes.func.isRequired,
  isPaneOpen: PropTypes.bool.isRequired,
  summaryPanelMode: PropTypes.string.isRequired,
};

export default StandbyGroundPuck;
