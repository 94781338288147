import {
  AssignmentFilter,
  PhaseOfFlightFilter,
  AirlineFilter,
  AlertFilter,
  IrropsMenuItemAction,
  PhaseOfFlight,
  IrropsCode,
  EntitlementNames,
  SubFleetFilter,
  AssignmentType,
  KeyCodes,
  TimeZones,
  airlineName,
  RefetchPageData,
  SummaryPanelMode,
  PuckType,
} from '../lib/constants';
import dayjs from 'dayjs';
import { getAirlineName, isNullOrWhitespace } from './displayUtils';
import { getApiErrorMessage, logError } from '../lib/appInsightsUtils';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';

/**
 * Checks that a given parameter string has the parameter 'code'
 * @param {string} paramString - the query parameter string, i.e. '?code=mockCode'
 * @return {bool} - true if the code parameter is included
 */
export const hasCodeInUrl = (paramsString) => {
  const searchParams = new URLSearchParams(paramsString);
  return searchParams.has('code');
};

export function* refetchDataRule(isPaneOpen, summaryPanelMode) {
  if (isPaneOpen === true) {
    yield RefetchPageData.FLIGHT_DETAIL_PANE;
  }

  if (summaryPanelMode === SummaryPanelMode.EXPANDED_DETAIL) {
    yield RefetchPageData.SUMMARY_FLIGHT_DETAIL;
  }

  if (summaryPanelMode === SummaryPanelMode.MINI_SUMMARY) {
    yield RefetchPageData.SUMMARY_MINI;
  }
}

/**
 * @description Returns the current window's base url.
 * @example https://aoc-test-frontdoor.azurefd.net, or https://localhost:3000
 * @return {string} - the base url
 */
export const getBaseUrl = () => {
  let uri = `${window.location.protocol}//${window.location.hostname}`;
  if (uri.includes('localhost')) {
    uri = `${uri}:${window.location.port}`;
  }
  return uri;
};

export const filterFlightAlerts = (flightLeg) => {
  // Returns empty array if not valid.
  if (
    flightLeg?.flightLegNotifications == null ||
    typeof flightLeg?.flightLegNotifications === 'undefined' ||
    (flightLeg?.flightLegNotifications?.length ?? 0) === 0
  ) {
    return [];
  }

  // create a local copy
  let currentNotifications = flightLeg.flightLegNotifications;

  return currentNotifications.filter((n) => n.notificationClosedTime === null);
};

/**
 * Generates uuid/guid for sessionId used in all the irrops modal submit
 * @return {string} - return uuid/guid for eg:7770fb37-1c33-4903-9344-c5e0d4a6817e
 */
export const uuidv4 = () => {
  var d = new Date().getTime(); //Timestamp
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

/**
 * @description Flattens the Flights API V1 response data.
 * @param {Array} flightData - The JSON response from the Flights API.
 * @return {Array} - a flattened structure of objects to bind to the Flights table.
 */
const flattenFlightLegs = (flightData) => {
  if (typeof flightData === 'undefined' || flightData === null || flightData.length === 0) {
    return [];
  }

  //console.time('flatten flights elapsed time');
  return flightData.map((row) => {
    return {
      departureDate: row.scheduledOperatingDateUTC,
      flightNumber: parseInt(row.flightNumber),
      aircraft: row.aircraft.aircraftRegistration,
      airline: row.operatingAirline,
      scheduledOrigin: row.origin.scheduled,
      orig: row.origin.actual,
      scheduledDestination: row.destination.scheduled,
      dest: row.destination.actual,
      etd: row.times.find((t) => t.name === 'OUT')?.estimatedTimeUTC,
      std: row.times.find((t) => t.name === 'OUT')?.scheduledTimeUTC,
      atd: row.times.find((t) => t.name === 'OUT')?.actualTimeUTC,
      off: row.times.find((t) => t.name === 'OFF')?.actualTimeUTC,
      on: row.times.find((t) => t.name === 'ON')?.actualTimeUTC,
      eta: row.times.find((t) => t.name === 'IN')?.estimatedTimeUTC,
      sta: row.times.find((t) => t.name === 'IN')?.scheduledTimeUTC,
      ata: row.times.find((t) => t.name === 'IN')?.actualTimeUTC,
      etoff: row.times.find((t) => t.name === 'OFF')?.estimatedTimeUTC,
      eton: row.times.find((t) => t.name === 'ON')?.estimatedTimeUTC,
      irropsCode: row.irregularOperationCode === null ? '' : row.irregularOperationCode,
      flightLegKey: row.flightLegKey,
      notifications: filterFlightAlerts(row),
      assignment: row.userRoleAssignmentLinks?.length,
      isEtopsFlight: row.etopsRequired,
      departureCount: row.departureCount,
      projectedDestination: row.projectedDestination,
      fleetType: row.aircraft.fleetType,
    };
  });
};

/**
 * @description filter origin and destination data.
 * @param {Array} flatFlights - flight flatten data.
 * @param {String} origin - to filtered selected origin data.
 * @param {Array} destination - to filtered selected destination data..
 * @return {Array} - filtred origin and destination data.
 */

const originDestFilter = (flatFlights, origin, destination) => {
  // we want to separate out stations (in or out)
  let stations = [];
  let origins = [];
  let destinations = [];

  if (Array.isArray(origin) && origin.length > 0 && Array.isArray(destination) && destination.length > 0) {
    origin.forEach((orig) => {
      // is it a station or origin?
      destination.some((d) => d === orig) ? stations.push(orig) : origins.push(orig);
    });
  }

  if (
    Array.isArray(origin) &&
    origin.length > 0 &&
    (!Array.isArray(destination) || (Array.isArray(destination) && destination.length === 0))
  ) {
    origin.forEach((orig) => {
      if (!stations.some((s) => s === orig)) {
        // aka not a station
        origins.push(orig);
      }
    });
  }

  if (Array.isArray(destination) && destination.length > 0) {
    destination.forEach((dest) => {
      if (!stations.some((s) => s === dest)) {
        // aka not a station
        destinations.push(dest);
      }
    });
  }

  return flatFlights.filter((flight) => {
    const originVal = flight.orig;
    const destVal = flight.dest;

    return (
      // is a station
      stations.some((s) => originVal === s || destVal === s) ||
      // or no origins, but needs a matching destination
      (origins.length === 0 && destinations.some((d) => destVal === d)) ||
      // or no dest, but needs a matching origin
      (destinations.length === 0 && origins.some((o) => originVal === o)) ||
      // or has a matching origin and destination
      (origins.some((o) => originVal === o) && destinations.some((d) => destVal === d))
    );
  });
};

/**
 * @description filter the flight data as per the their fields.
 * @param {Array} flatFlights - flight flatten data.
 * @param {Object} filters - The current filters being used by the Flights view.
 * @return {Array} - a filtered array and bind to the Flights table.
 */

const filterFlightData = {
  alertAllFilter: (flatFlights) => flatFlights.filter((item) => item.notifications.length > Number(0)),
  alertNoFilter: (flatFlights, filters) =>
    flatFlights?.filter((item) => {
      return item.notifications.find((notifications) => {
        return filters.alerts.some((alertCode) => notifications.notificationName === alertCode);
      });
    }),
  airlineFilter: (flatFlights, filters) => {
    if (!filters.airline.includes(AirlineFilter.ALL)) {
      return flatFlights.filter((item) =>
        filters.airline.some((airlineCode) => item.airline === getAirlineName(airlineCode)),
      );
    }
  },
  aircraftFilter: (flatFlights, filters) => {
    return flatFlights.filter((item) => filters.aircraft.some((aircraft) => item.aircraft === aircraft));
  },
  filterByAssignmentMyFlights: (flatFlights, assignments) => {
    return flatFlights.filter((item) => assignments?.includes(item.flightLegKey));
  },
  filterByAssignmentMyAircraft: (flatFlights, assignments) => {
    return flatFlights.filter((item) => assignments?.includes(item.aircraft));
  },
  flightFilter: (flatFlights, filters) => {
    return flatFlights.filter((item) => filters.flight.some((flight) => item.flightNumber === Number(flight)));
  },
  fleetTypeFilter: (flatFlights, filters) => {
    return flatFlights.filter((item) => filters.fleets.some((fleet) => item.fleetType === fleet));
  },
  hideCanceledFilter: (flatFlights) => flatFlights?.filter((item) => item.irropsCode !== 'CXL'),
  phaseOfFlightFilter: (flatFlights, filters) => {
    return flatFlights.filter((item) => {
      let itemPhase = getPhaseOfFlightFilter(item);
      return filters.flightPhase.some((phase) => phase === itemPhase);
    });
  },
};

/**
 * @description Flattens the filtered Flights API response data.
 * @param {Array} flightData - The JSON response from the Flights API V1
 * @param {Object} filters - The current filters being used by the Flights view.
 * @return {Array} - a filtered structure of flat objects to bind to the Flights table.
 */
export const getFlattenedFilteredFlightLegs = (flightData, hideCancelled, filters) => {
  flightData = flattenFlightLegs(flightData);
  return getFilteredFlightLegs(flightData, hideCancelled, filters);
};

/**
 * @description Filters the Flights API V2 response data
 * @param {Boolean} hideCancelled - Boolean used to specify whether to hide cancelled flights.
 * @param {Array} flightData - The JSON response from the Flights API V2.
 * @param {Object} filters - The current filters being used by the Flights view.
 * @return {Array} - a filtered structure of flat objects to bind to the Flights table.
 */

export const getFilteredFlightLegs = (flightData, hideCancelled, filters, activeAssignments) => {
  if (typeof flightData === 'undefined' || flightData === null || flightData.length === 0) {
    return [];
  }
  let flatFlights = flightData;
  if (!filters || filters.length === 0) {
    // If no filter specified, default to filtering canceled flights out of the list
    flatFlights = flatFlights.filter((item) => item.irropsCode !== 'CXL');
    return flatFlights;
  }

  // Apply filters, if any exist
  //FLIGHT
  if (Array.isArray(filters.flight) && filters.flight.length > 0) {
    flatFlights = filterFlightData.flightFilter(flatFlights, filters);
  }

  // AIRCRAFT
  if (Array.isArray(filters.aircraft) && filters.aircraft.length > 0) {
    flatFlights = filterFlightData.aircraftFilter(flatFlights, filters);
  }

  // Filter origin and destination based on station pairs
  if (
    (Array.isArray(filters.origin) && filters.origin.length > 0) ||
    (Array.isArray(filters.destination) && filters.destination.length > 0)
  ) {
    flatFlights = originDestFilter(flatFlights, filters.origin, filters.destination);
  }

  // IRROPS CODE
  // if (filters.irropsCode) {
  //   flatFlights = flatFlights.filter(
  //     (item) => item.irropsCode === filters.irropsCode
  //   );
  // }

  // HIDE CANCELED
  if (hideCancelled) {
    flatFlights = filterFlightData.hideCanceledFilter(flatFlights);
  }

  // Filter ASSIGNMENTLIST
  if (
    Array.isArray(filters.assignmentList) &&
    filters.assignmentList?.length > 0 &&
    !(
      filters.assignmentList.includes(AssignmentFilter.ALL_AIRCRAFT) ||
      filters.assignmentList.includes(AssignmentFilter.ALL_FLIGHTS)
    )
  ) {
    const flightData = flatFlights;
    let myFlightsData;
    let myAircraftData;
    if (filters.assignmentList.includes(AssignmentFilter.MY_FLIGHTS)) {
      flatFlights = filterFlightData.filterByAssignmentMyFlights(flightData, activeAssignments?.flightLegKeys);
      myFlightsData = flatFlights;
    }

    if (filters.assignmentList.includes(AssignmentFilter.MY_AIRCRAFT)) {
      flatFlights = filterFlightData.filterByAssignmentMyAircraft(flightData, activeAssignments?.aircraft);
      myAircraftData = flatFlights;
    }

    if (
      filters.assignmentList.includes(AssignmentFilter.MY_AIRCRAFT) &&
      filters.assignmentList.includes(AssignmentFilter.MY_FLIGHTS)
    ) {
      flatFlights = [...myFlightsData, ...myAircraftData];
    }
  }

  // PHASE OF FLIGHT
  if (Array.isArray(filters.flightPhase) && filters.flightPhase.length > 0) {
    // All Flight phase - Don't filter
    if (!filters.flightPhase.includes(PhaseOfFlightFilter.ALL_FLIGHTS)) {
      flatFlights = filterFlightData.phaseOfFlightFilter(flatFlights, filters);
    }
  }

  // Airline
  if (Array.isArray(filters.airline) && filters.airline.length > 0) {
    // All Airlines - Don't filter
    if (!filters.airline.includes(AirlineFilter.ALL)) {
      flatFlights = filterFlightData.airlineFilter(flatFlights, filters);
    }
  }

  // Alerts
  if (Array.isArray(filters.alerts) && filters.alerts.length > 0) {
    if (filters.alerts.includes(AlertFilter.ALL)) {
      // All Notifications
      flatFlights = filterFlightData.alertAllFilter(flatFlights);
    } else if (!filters.alerts.includes(AlertFilter.NO_FILTER)) {
      // Anything except '--' / No Filter.
      // Filter based on the "name" value.
      flatFlights = filterFlightData.alertNoFilter(flatFlights, filters);
    }
  }

  //fleetType
  if (Array.isArray(filters.fleets) && filters.fleets.length > 0 && !filters.fleets.includes(SubFleetFilter.ALL)) {
    flatFlights = filterFlightData.fleetTypeFilter(flatFlights, filters);
  }

  return flatFlights;
};

/**
 * @description Checks that a URL string is meant to use the HTTP or HTTPS protocol.
 * @param {string} url - URL to be tested.
 */
export const isUrlHttpOrHttps = (url) => {
  if (url === null || typeof url === 'undefined') return false;

  let protocol = '';
  try {
    protocol = new URL(url).protocol;
  } catch (ex) {
    logError(getApiErrorMessage(ex), 'utils', 'isUrlHttpOrHttps');
  }

  if (protocol === 'http:') return true;
  if (protocol === 'https:') return true;

  return false;
};

/**
 * @description Maps OOOI flight phase to phase of flight that is used in filtering
 * @param {object} flightLeg - The flight we are checking
 * @returns {string} - The phase of flight for filter
 */
export const getPhaseOfFlightFilter = (flightLeg) => {
  let flightPhase = getPhaseOfFlight(flightLeg);
  let flightPhaseFilter = null;

  switch (flightPhase) {
    case PhaseOfFlight.PRE_FLIGHT:
      flightPhaseFilter = PhaseOfFlightFilter.PRE_FLIGHT;
      break;
    case PhaseOfFlight.OUT:
      flightPhaseFilter = PhaseOfFlightFilter.TAXI_OUT;
      break;
    case PhaseOfFlight.OFF:
    case PhaseOfFlight.OFF_ONLY:
      flightPhaseFilter = PhaseOfFlightFilter.EN_ROUTE;
      break;
    case PhaseOfFlight.ON:
    case PhaseOfFlight.ON_ONLY:
    case PhaseOfFlight.OFF_ON_ONLY:
      flightPhaseFilter = PhaseOfFlightFilter.TAXI_IN;
      break;
    case PhaseOfFlight.IN:
    case PhaseOfFlight.IN_ONLY:
    case PhaseOfFlight.ON_IN_ONLY:
    case PhaseOfFlight.OUT_IN_ONLY:
      flightPhaseFilter = PhaseOfFlightFilter.POST_FLIGHT;
      break;
    default:
  }
  return flightPhaseFilter;
};

/**
 * @description Determine a flights 'phase of flight'
 * @param {object} flightLeg - The flight we are checking
 * @returns {string} - The phase of flight
 */
export const getPhaseOfFlight = (flightLeg) => {
  let flightPhase = PhaseOfFlight.UNKNOWN;

  // Can't be null
  if (flightLeg) {
    // Pre Flight
    if (flightLeg.atd === null && flightLeg.off === null && flightLeg.on === null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.PRE_FLIGHT;
    }
    // Out
    else if (flightLeg.atd !== null && flightLeg.off === null && flightLeg.on === null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.OUT;
    }
    // Off
    else if (flightLeg.atd !== null && flightLeg.off !== null && flightLeg.on === null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.OFF;
    }
    // On
    else if (flightLeg.atd !== null && flightLeg.off !== null && flightLeg.on !== null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.ON;
    }
    // In
    else if (flightLeg.atd !== null && flightLeg.off !== null && flightLeg.on !== null && flightLeg.ata !== null) {
      flightPhase = PhaseOfFlight.IN;
    }
    // Off only
    else if (flightLeg.atd === null && flightLeg.off !== null && flightLeg.on === null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.OFF_ONLY;
    }
    // On only
    else if (flightLeg.atd === null && flightLeg.off === null && flightLeg.on !== null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.ON_ONLY;
    }
    // In only
    else if (flightLeg.atd === null && flightLeg.off === null && flightLeg.on === null && flightLeg.ata !== null) {
      flightPhase = PhaseOfFlight.IN_ONLY;
    }
    // Off On only
    else if (flightLeg.atd === null && flightLeg.off !== null && flightLeg.on !== null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.OFF_ON_ONLY;
    }
    // Out On only
    else if (flightLeg.atd !== null && flightLeg.off === null && flightLeg.on !== null && flightLeg.ata === null) {
      flightPhase = PhaseOfFlight.OUT_ON_ONLY;
    }
    // On In only
    else if (flightLeg.atd === null && flightLeg.off === null && flightLeg.on !== null && flightLeg.ata !== null) {
      flightPhase = PhaseOfFlight.ON_IN_ONLY;
    } else if (flightLeg.out !== null && flightLeg.in !== null && flightLeg.off === null && flightLeg.on === null) {
      flightPhase = PhaseOfFlight.OUT_IN_ONLY;
    } else {
      flightPhase = PhaseOfFlight.UNKNOWN;
    }
  }

  return flightPhase;
};

/**
 * @description Determine a Add Continue Flight'
 * @param {object} flightLeg - The flight we are checking
 * @returns {string} - bool
 */
export const getAddContinueFlight = (showContinueFlight, flightLeg) => {
  if (
    showContinueFlight &&
    flightLeg.airline === airlineName.OOAIRLINE &&
    (flightLeg.irropsCode === IrropsCode.AIR_TURN_BACK ||
      flightLeg.irropsCode === IrropsCode.DIVERT_FLIGHT ||
      flightLeg.irropsCode === IrropsCode.REROUTE_FLIGHT ||
      flightLeg.irropsCode === IrropsCode.BLOCK_TURN_BACK ||
      flightLeg.irropsCode === IrropsCode.RETURN_TO_GATE) &&
    flightLeg.irropsCode !== 'CXL'
  )
    return true;
};

/**
 * @description Determine a flights 'phase of flight' and return the appropriate Irrops menu
 * @param {object} flightLeg - The flight we are checking
 * @returns {array} - array of menu items
 */
export const getIrropsMenu = (flightLeg, showContinueFlight) => {
  let IrropsMenu = [];
  let FlightPhase = getPhaseOfFlight(flightLeg);

  // Canceled flights can only be reinstated
  if (flightLeg.irropsCode === 'CXL') {
    IrropsMenu.push({
      menuText: 'Reinstate Flight',
      menuActionType: IrropsMenuItemAction.REINSTATE_FLIGHT,
      entitlementNeeded: EntitlementNames.REINSTATE,
    });
  } else {
    // Build Irrops menu based on phase of flight and Airline
    switch (FlightPhase) {
      case PhaseOfFlight.PRE_FLIGHT:
        IrropsMenu.push({
          menuText: 'Cancel Flight',
          menuActionType: IrropsMenuItemAction.CANCEL_FLIGHT,
          entitlementNeeded: EntitlementNames.CANCEL,
        });
        if (flightLeg.airline === 'AS' && flightLeg.isEtopsFlight) {
          IrropsMenu.push({
            menuText: 'ReDispatch Flight',
            menuActionType: IrropsMenuItemAction.REDISPATCH_FLIGHT,
            entitlementNeeded: EntitlementNames.REDISPATCH,
          });
        }
        IrropsMenu.push({
          menuText: 'ReRoute Flight',
          menuActionType: IrropsMenuItemAction.REROUTE_FLIGHT,
          entitlementNeeded: EntitlementNames.REROUTE,
        });

        break;
      case PhaseOfFlight.OUT:
        if (flightLeg.airline === 'AS' && flightLeg.isEtopsFlight) {
          IrropsMenu.push({
            menuText: 'ReDispatch Flight',
            menuActionType: IrropsMenuItemAction.REDISPATCH_FLIGHT,
            entitlementNeeded: EntitlementNames.REDISPATCH,
          });
        }
        IrropsMenu.push(
          {
            menuText: 'ReRoute Flight',
            menuActionType: IrropsMenuItemAction.REROUTE_FLIGHT,
            entitlementNeeded: EntitlementNames.REROUTE,
          },
          {
            menuText: 'Block Turn Back (RET)',
            menuActionType: IrropsMenuItemAction.BLOCK_TURN_BACK,
            entitlementNeeded: EntitlementNames.BLOCKTURNBACK,
          },
        );

        break;
      case PhaseOfFlight.OFF:
        IrropsMenu.push({
          menuText: 'Divert Flight',
          menuActionType: IrropsMenuItemAction.DIVERT_FLIGHT,
          entitlementNeeded: EntitlementNames.DIVERT,
        });
        if (flightLeg.airline === 'AS' && flightLeg.isEtopsFlight) {
          IrropsMenu.push({
            menuText: 'ReDispatch Flight',
            menuActionType: IrropsMenuItemAction.REDISPATCH_FLIGHT,
            entitlementNeeded: EntitlementNames.REDISPATCH,
          });
        }
        IrropsMenu.push({
          menuText: 'Air Turn Back',
          menuActionType: IrropsMenuItemAction.AIR_TURN_BACK,
          entitlementNeeded: EntitlementNames.AIRTURNBACK,
        });
        break;
      case PhaseOfFlight.OFF_ONLY:
        IrropsMenu.push({
          menuText: 'Divert Flight',
          menuActionType: IrropsMenuItemAction.DIVERT_FLIGHT,
          entitlementNeeded: EntitlementNames.DIVERT,
        });
        if (flightLeg.airline === 'AS' && flightLeg.isEtopsFlight) {
          IrropsMenu.push({
            menuText: 'ReDispatch Flight',
            menuActionType: IrropsMenuItemAction.REDISPATCH_FLIGHT,
            entitlementNeeded: EntitlementNames.REDISPATCH,
          });
        }
        IrropsMenu.push({
          menuText: 'Air Turn Back',
          menuActionType: IrropsMenuItemAction.AIR_TURN_BACK,
          entitlementNeeded: EntitlementNames.AIRTURNBACK,
        });
        break;
      default:
    }
  }

  //Update irrops reason any flight past present and future.
  if (
    flightLeg.irropsCode !== null &&
    flightLeg.irropsCode !== '' &&
    flightLeg.irropsCode !== IrropsCode.EQUIPMENT_SWAP
  ) {
    IrropsMenu.push({
      menuText: 'Update Irrops Reason',
      menuActionType: IrropsMenuItemAction.UPDATE_IRROPS_REASON,
      entitlementNeeded: EntitlementNames.UPDATEIRROPSREASON,
    });
  }

  if (getAddContinueFlight(showContinueFlight, flightLeg)) {
    IrropsMenu.push({
      menuText: 'Add Continuation Flight',
      menuActionType: IrropsMenuItemAction.ADD_CONTINUE_FLIGHT,
      entitlementNeeded: EntitlementNames.ADDFLIGHT,
    });
  }

  return IrropsMenu;
};

export const getFlightPhaseStyle = (flightLeg, taxiBlockFlag = false) => {
  let className = '';

  let FlightPhase = getPhaseOfFlight(flightLeg);

  if (taxiBlockFlag && flightLeg.irropsCode != null && flightLeg.irropsCode === IrropsCode.RETURN_TO_GATE) {
    className = 'taxi-block phase-return-to-gate';
    return className;
  }

  switch (FlightPhase) {
    case PhaseOfFlight.PRE_FLIGHT:
      className = 'phase-pre-flight';
      break;
    case PhaseOfFlight.OUT:
      className = 'phase-out';
      break;
    case PhaseOfFlight.OFF:
      className = 'phase-off';
      break;
    case PhaseOfFlight.ON:
      className = 'phase-on';
      break;
    case PhaseOfFlight.IN:
      className = 'phase-in';
      break;
    case PhaseOfFlight.OFF_ONLY:
      className = 'phase-off-only';
      break;
    case PhaseOfFlight.ON_ONLY:
      className = 'phase-on-only';
      break;
    case PhaseOfFlight.IN_ONLY:
      className = 'phase-in-only';
      break;
    case PhaseOfFlight.OFF_ON_ONLY:
      className = 'phase-off-on-only';
      break;
    case PhaseOfFlight.OUT_ON_ONLY:
      className = 'phase-out-on-only';
      break;
    case PhaseOfFlight.ON_IN_ONLY:
      className = 'phase-on-in-only';
      break;
    case PhaseOfFlight.OUT_IN_ONLY:
      className = 'phase-out-in-only';
      break;
    default:
  }
  className = taxiBlockFlag && className !== '' ? `taxi-block ${className}` : className;

  return className;
};

/**
 * Method which returns false if flight is OOOI
 * @param {*} data - flight data
 * @returns
 */
export const filterFlightsForDoubleClick = (data) => {
  switch (Number(getPhaseOfFlight(data))) {
    case Number(PhaseOfFlight.IN):
    case Number(PhaseOfFlight.OFF):
    case Number(PhaseOfFlight.ON):
    case Number(PhaseOfFlight.OUT):
      return false;
    default:
      return true;
  }
};

/**
 * @description sets the width of the popper which contains the dropdown items list. this function allows the dropdownitems list width to be dynamic based on width of the text inside dropdown menu items. the width of dropdown list will be equal to the ChipInputDropdown component or more based on the items.
 * @param {function} setWidth function to get width of popper
 * @returns popper modifiers array
 */
export const getInputPoppersModifier = (
  setWidth = () => {
    /* this is intentional */
  },
) => {
  const modifiersArray = [
    {
      name: 'flip',
      enabled: false,
      options: {
        flipVariations: false,
        fallbackPlacements: ['bottom-start', 'bottom-end'],
        allowedAutoPlacements: ['bottom-start', 'bottom-end', 'top-start'],
      },
    },
    {
      name: 'setPopperWidth',
      enabled: true,
      order: 849,
      fn: setWidth, // (the function defined before)
    },
  ];
  return modifiersArray;
};

/**
 * @description sets the width of the popper which contains the dropdown items list. this function allows the dropdownitems list width to be dynamic based on width of the text inside dropdown menu items. the width of dropdown list will be equal to the ChipInputDropdown component or more based on the items.
 * @param {*} popper element data object
 * @returns modified popper element data object
 */
export const setPopperWidth = (data) => {
  const { width, left, right } = data.offsets.reference;
  if (data.offsets.popper.width <= width) {
    data.styles.width = width;
    data.offsets.popper.width = width;
  }
  data.offsets.popper.left = left;
  data.offsets.popper.right = right;

  return data;
};

/**
 * @description Creates the default flights filter using today's UTC day
 * @param {string} defaultAssignmentFilter - The default assignment filter
 * @param {string} timeZone - The user's timezone
 * @returns - The default filter object
 */
export const getDefaultFilter = (defaultAssignmentFilter, timeZone = TimeZones.UTC) => {
  let defaultStartDate;
  let defaultEndDate;

  if (timeZone === TimeZones.UTC) {
    defaultStartDate = dayjs.utc().startOf('day');
    defaultEndDate = dayjs.utc(defaultStartDate).add(1, 'day');
  } else {
    defaultStartDate = dayjs().tz(timeZone, true).startOf('day');
    defaultEndDate = dayjs().tz(timeZone, true).add(1, 'day').endOf('day');
  }

  return {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    flight: [],
    aircraft: [],
    origin: [],
    destination: [],
    assignmentList: defaultAssignmentFilter ? [defaultAssignmentFilter] : [],
    flightPhase: [PhaseOfFlightFilter.ALL_FLIGHTS],
    airline: [AirlineFilter.ALL],
    alerts: [AlertFilter.NO_FILTER],
    fleets: [SubFleetFilter.ALL],
    timezone: timeZone,
  };
};

/**
 * @description Maps AirlineFilter value to return airline code (name)
 * @param {number} - AirlineFilter value
 * @returns {string} - Airline code (name)
 */
export const mapAirlineFilterToAirlineCode = (airlineFilter) => {
  switch (airlineFilter) {
    case AirlineFilter.AS:
      return 'AS';
    case AirlineFilter.QX:
      return 'QX';
    case AirlineFilter.OO:
      return 'OO';
    default:
      return '';
  }
};

/**
 * @description Maps airline code (name) to return AirlineFilter value
 * @param {string} - Airline code (name)
 * @returns {number} - AirlineFilter value
 */
export const mapAirlineCodeToAirlineFilter = (airlineCode) => {
  switch (airlineCode) {
    case 'AS':
      return AirlineFilter.AS;
    case 'QX':
      return AirlineFilter.QX;
    case 'OO':
      return AirlineFilter.OO;
    default:
      return null;
  }
};

/**
 * @description Get unique aircraft list
 * @param {Array} flightLegs - list of flattened flight legs
 * @param {object} groundEventsData -  - dictionary of aircraft keys to list of ground events
 * @returns {Array} - array of unique sorted tail numbers
 */
export const getUniqueAircraftList = (flightLegs = [], groundEventsData = {}) => {
  // If no data, return
  if (!(flightLegs.length > 0) && !!groundEventsData && !(Object.keys(groundEventsData).length > 0)) return [];

  // Push list of flight leg aircrafts with airline and fleettype to all aircrafts list
  const allAircraftsList = flightLegs.map((f) => {
    return {
      aircraft: f.aircraft,
      airline: f.airline,
      fleetType: f.fleetType,
    };
  });

  // Push ground events aircrafts to all aircrafts list from groundEventData
  Object.keys(groundEventsData).forEach((aircraft) => {
    // Only push ground events aircraft if necessary
    if (groundEventsData[aircraft].length > 0) {
      allAircraftsList.push({
        aircraft: aircraft,
        airline: groundEventsData[aircraft][0].operatingAirline,
        fleetType: groundEventsData[aircraft][0].fleetType,
      });
    }
  });

  // Get rid of duplicates
  const uniqueAircraftsList = Array.from(new Set(allAircraftsList.map((a) => a.aircraft))).map((id) => {
    return allAircraftsList.find((a) => a.aircraft === id);
  });

  //Return list of unique aircrafts
  return orderBy(uniqueAircraftsList, 'aircraft', 'asc');
};
/**
 * @description Get sorted aircraft list
 * @param {Array} aircraftList - list of aircrafts
 * @param {Array} sortedFleetType - list of sorted subfleets based on sorting priority
 * @param {string} operatingAirlineOfRole - operating airline mapped to the role
 * @returns {Array} - array of unique sorted tail numbers
 */
export const getSortedAircraftList = (aircraftList = [], sortedFleetType = [], operatingAirlineOfRole = '') => {
  // If no data, return
  if (!(aircraftList.length > 0)) return [];

  const defaultSubfleetSortPriority = -1;

  // Push list of flight leg aircrafts with airline and fleettype to all aircrafts list
  const allAircraftsList = aircraftList.map((f) => {
    let priority = sortedFleetType.find((subfleet) => f.fleetType === subfleet.fleetType)?.subFleetSortingPriority;

    return {
      aircraft: f.aircraft,
      airline: f.airline,
      fleetType: f.fleetType,
      subFleetSortingOrder: !!priority ? priority : defaultSubfleetSortPriority,
    };
  });

  let airlineSortOrder;
  switch (operatingAirlineOfRole) {
    case 'QX':
      airlineSortOrder = ['QX', 'AS', 'OO'];
      break;
    case 'OO':
      airlineSortOrder = ['OO', 'AS', 'QX'];
      break;
    case 'AS':
    default: //AS is set as default
      airlineSortOrder = ['AS', 'OO', 'QX'];
  }

  const createAirlineSortOrder = (airline) => {
    return airline === airlineSortOrder[0]
      ? '1'
      : airline === airlineSortOrder[1]
      ? '2'
      : airline === airlineSortOrder[2]
      ? '3'
      : airline;
  };

  // Sort by airline, fleetype, then aircraft registration
  const sortedAircrafts = orderBy(
    allAircraftsList,
    [(aircraft) => createAirlineSortOrder(aircraft.airline), 'subFleetSortingOrder', 'aircraft'],
    ['asc', 'asc', 'asc'],
  );

  //Return list of unique aircrafts (including airline and fleet type)
  return sortedAircrafts;
};

/**
 * @description search an array and return array of values matching criteria
 * @param {string} searchCharacters - search for these characters in the array
 * @param {array} arrayToSearch - array of name value pairs
 * @returns {array} - array of all name value pairs where searchCharacters match
 */
export const smartSearch = (searchCharacters, arrayToSearch) => {
  return arrayToSearch.filter(
    (item) => !searchCharacters || item.name.toUpperCase().includes(searchCharacters.toUpperCase()),
  );
};

/**
 * Checks whether an element has overflow either by height, width, or both
 * @param {HTMLElement} element - dom element reference
 * @param {bool} height - whether to check the height for overflow
 * @param {bool} width - whether to check the width for overflow
 */
export const elementHasOverflow = (element, height = true, width = true) => {
  return (
    element &&
    ((height && element.scrollHeight > element.clientHeight) || (width && element.scrollWidth > element.clientWidth))
  );
};

/**
 * Checks whether two objects are equal
 * @param {object} a - first object to compare
 * @param {object} b - second object to compare
 */
export const jsonEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * removes all the given element's localStorage items
 * @param {Array.<string>} elementIdList
 */
export const clearComponentScrollState = (elementIdList) => {
  elementIdList.forEach((elementId) => {
    localStorage.removeItem(elementId);
  });
};

/**
 *
 * @param {string} elementId
 * @param {number} scrollTop
 * @param {number} scrollLeft
 * @returns
 */
export const setComponentScroll = debounce((elementId, scrollTop, scrollLeft, params = {}) => {
  if (isNullOrWhitespace(elementId) || (isNullOrWhitespace(scrollTop) && isNullOrWhitespace(scrollLeft))) {
    return;
  }
  if (typeof Storage == 'undefined') {
    return;
  }
  // create an object with scroll positions
  let scrollPosition = {
    scrollTop,
    scrollLeft,
    ...params,
  };
  // set localStorage with elementId and scrollPosition object
  localStorage.setItem(elementId, JSON.stringify(scrollPosition));
}, 1000);

/**
 *
 * @param {string} elementId
 * @returns
 */
export const getComponentScroll = (elementId) => {
  return getStoredObject(elementId);
};

export const setStoredObject = (key, value) => {
  if (isNullOrWhitespace(key)) {
    return;
  }
  if (typeof Storage == 'undefined') {
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
};

/// <summary>
/// Check if the string is null or whitespace
/// </summary>
export const getStoredObject = (key) => {
  if (isNullOrWhitespace(key)) {
    return null;
  }

  if (typeof Storage == 'undefined') {
    return null;
  }

  let storedObject = localStorage.getItem(key);
  if (storedObject == null) {
    return null;
  }
  return JSON.parse(storedObject);
};
/**
 * filter Notes function that checks to see whether an array contains a watch flight value.
 * @param flightLegKey current flight leg flightLegKey
 * @returns {boolean} TRUE if the value exists in the array. FALSE otherwise.
 */
export const filterNotesTag = (operationalNotes, flightLegKey, noteTag) => {
  if (operationalNotes !== undefined && operationalNotes.length !== 0) {
    let filterdata = operationalNotes.filter(function (element) {
      return (
        element.noteTag === noteTag && element.flightLeg !== null && element.flightLeg.flightLegKey === flightLegKey
      );
    });
    return filterdata;
  }
};

/**
 * @description returns a focusNextElement
 * @param {*} event
 * @param {*} target
 * @returns returns a focusNextElement
 */
export const focusNextElement = (event, target, formClassName) => {
  let clickEventType = 'click';
  if (event == null || event.target == null) return;
  //Note that this doesn't honour tab-indexes
  if (
    event.type !== clickEventType &&
    event.keyCode !== KeyCodes.TAB &&
    event.keyCode !== KeyCodes.ENTER &&
    event.keyCode !== KeyCodes.SPACE
  ) {
    return;
  }
  let isBackwardMove = event.type === clickEventType ? false : event.keyCode === KeyCodes.TAB && event.shiftKey;
  event.preventDefault();
  //Isolate the node that we're after
  const currentNode = target ?? event.target;
  const newCurrentNode = document.getElementById(target?.id);
  //find all tab-able elements
  let allElements = null;
  if (formClassName != null) {
    allElements = document.querySelectorAll(
      `input.${formClassName}, textarea.${formClassName}, button.${formClassName}`,
    );
  } else {
    allElements = document.querySelectorAll(`input, textarea, button`);
  }

  //Find the current tab index.
  const currentIndex = [...allElements].findIndex(
    (el) => currentNode?.isEqualNode(el) || newCurrentNode?.isEqualNode(el),
  );

  let delta = isBackwardMove ? (currentIndex > 0 ? -1 : 0) : 1;
  //focus the following element
  const targetIndex = (currentIndex + delta) % allElements.length;
  allElements[targetIndex].focus();
};

/**
 * check and get info by pucks from operationalNotes by flight leg
 * @param flightLegKey current flight leg flightLegKey
 * @returns {object} return object based on filter result
 */
export const getInfoByPucks = (operationalNotes, flightLegKey) => {
  if (operationalNotes !== undefined && operationalNotes.length !== 0) {
    const infoByData = operationalNotes.filter(function (element) {
      return (
        element.noteTag === 'InfoByDelay' &&
        element.operationalNoteCreateDate !== null &&
        element.operationalNoteClosedDate === null &&
        element.flightLeg !== null &&
        element.flightLeg.flightLegKey === flightLegKey
      );
    });
    if (infoByData.length > 0) {
      return { isInfoBy: true, operationalNoteKey: infoByData[0].operationalNoteKey };
    } else {
      return { isInfoBy: false, operationalNoteKey: '' };
    }
  }
};

export const getAssignmentTypeList = () => {
  return [{ typeName: AssignmentType.FLIGHT }, { typeName: AssignmentType.AIRCRAFT }];
};

/**
 * Converts rem to pixel value
 * @param {string} rem - rem value with unit i.e. '8rem'
 * @returns pixel value
 */
export const convertRemToPixels = (rem) => {
  try {
    const remValue = parseFloat(rem);
    const baseFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    const result = remValue * baseFontSize;
    if (result) {
      return result;
    } else {
      return 0;
    }
  } catch (error) {
    console.error(error);
  }
  return 0;
};

/**
 * @description  Compare json default filter and current save filter retun boolean
 * @returns - true or false
 */
export const isDefaultFilter = (currentFilter) => {
  let defaultFilter = getDefaultFilter(); // get default filter
  //Reset hide canceled for Compare json current filter set as default
  let resetHideCanceled = { ...currentFilter, hideCanceled: defaultFilter.hideCanceled };
  return jsonEqual(defaultFilter, resetHideCanceled);
};

/**
 * Returns a deep copy of the given object
 * @param {Object} object
 * @returns
 */
export const deepCopy = (object = null) => {
  return JSON.parse(JSON.stringify(object));
};

/**
 * Returns whether or not a given PuckType constant is a ground event type
 * Current Ground Event types: PuckType.GROUND_OTS, PuckType.GROUND_STANDBY,
 * and PuckType.GROUND_UNAVAILABLE
 * @param {string} puckType - A PuckType constant value
 * @returns - whether or not the puck type is a ground event
 */
export const isGroundEventPuck = (puckType) =>
  puckType === PuckType.GROUND_OTS || puckType === PuckType.GROUND_STANDBY || puckType === PuckType.GROUND_UNAVAILABLE;
