import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { ReactComponent as ConfigurationFileIcon } from '../../assets/images/configuration-file-icon.svg';
import { ReactComponent as ConfigurationTrashIcon } from '../../assets/images/configuration-trash-icon.svg';
import SaveConfigModal from './SaveConfigModal/SaveConfigModal';
import { useConfigStore, useConfigDispatch } from '../../hooks/useConfigStore/useConfigStore';
import {
  useFetchUserConfigurations,
  useFetchUserConfigurationById,
} from '../../hooks/useUserConfigurationQuery/useUserConfigurationQuery';
import PropTypes from 'prop-types';
import './Configuration.css';
import { useHistory } from 'react-router-dom';

/**
 * Configuration Page component
 * @param {*} children
 * @returns
 */
const ConfigurationPage = ({ children }) => {
  return (
    <div data-cy="configuration-page" className="configuration">
      <div className="page-header">
        <div data-cy="configuration-header-text">Saved Configuration Files</div>
      </div>
      {children}
    </div>
  );
};

/**
 * Configuration Icon component
 * @returns Configuration Icon
 */
const ConfigurationIcon = () => {
  return <ConfigurationFileIcon data-cy="configuration-file-icon" className="configuration-file-icon" />;
};

const Configuration = () => {
  const { openSaveConfig } = useConfigStore();
  const { closeSaveConfigModal } = useConfigDispatch();

  const { isLoading: isLoadingList, isError: hasListError, userConfigurations } = useFetchUserConfigurations();
  const { isError: hasRowError, userConfiguration, setConfigurationId, onLoaded } = useFetchUserConfigurationById();
  const history = useHistory();

  useEffect(() => {
    onLoaded(() => {
      history.push('/flights?view=gantt');
    });
  }, []);

  if (isLoadingList) {
    return <ConfigurationPage>Loading...</ConfigurationPage>;
  }

  if (hasListError) {
    return <ConfigurationPage>Failed to retrieve User Configurations. Please try again.</ConfigurationPage>;
  }

  if (hasRowError) {
    return <ConfigurationPage>Failed to retrieve the selected User Configuration. Please try again.</ConfigurationPage>;
  }

  const handleDeleteConfig = () => {};

  const handleApplyConfig = (id) => {
    setConfigurationId(id);
  };

  /**
   * @description handling closing add flight modal.
   */
  const handleModalCancel = () => {
    closeSaveConfigModal();
  };

  return (
    <ConfigurationPage>
      <TableContainer
        className="configuration-table-container"
        data-cy="configuration-table-container"
        component={Paper}
      >
        <Table className="configuration-table">
          <TableHead className="equipment-change-table-head">
            <TableRow>
              <TableCell key="table-header-icon" data-cy="table-header-icon">
                {' '}
              </TableCell>
              <TableCell key="table-header-filename" data-cy="table-header-filename" className="th-table-header-cell">
                File Name
              </TableCell>
              <TableCell key="table-header-date" data-cy="table-header-date" className="th-table-header-cell">
                Date Modified
              </TableCell>
              <TableCell
                key="table-header-modifiedby"
                data-cy="table-header-modifiedby"
                className="th-table-header-cell"
              >
                Modified By
              </TableCell>
              <TableCell key="table-header-delete" data-cy="table-header-delete">
                {' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-cy="configuration-table-body" className="configuration-table-body">
            {Array.from(userConfigurations).map((config, i) => (
              <TableRow
                key={`table-row-${config.configurationId}`}
                style={{
                  mixBlendMode:
                    config.configurationId === userConfiguration?.configurationId ? 'color-dodge' : 'normal',
                }}
              >
                <TableCell key={`row-cell-icon-${config.configurationId}`} className={`td-icon`}>
                  {ConfigurationIcon()}
                </TableCell>
                <TableCell
                  key={`row-cell-file-${config.configurationId}`}
                  className={` config-file td-${config.configurationName}`}
                  data-cy="configuration-configuration-name"
                  onClick={() => handleApplyConfig(config.configurationId)}
                >
                  {config.configurationName}
                </TableCell>
                <TableCell key={`row-cell-date-${config.configurationId}`} className={`td-${config.dateModified}`}>
                  <span>{config.dateModified}</span> <span className="modified-time">{config.timeModified}</span>
                </TableCell>
                <TableCell key={`row-cell-by-${config.configurationId}`} className={`td-${config.userName}`}>
                  {config.userName}
                </TableCell>
                <TableCell key={`row-cell-delete-${config.configurationId}`} className={`td-delete`}>
                  <ConfigurationTrashIcon
                    onClick={() => handleDeleteConfig(config.configurationId)}
                    data-cy="configuration-trash-icon"
                    className="config-trash-icon"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openSaveConfig && <SaveConfigModal onClose={handleModalCancel} showModal={openSaveConfig} />}
    </ConfigurationPage>
  );
};

ConfigurationPage.propTypes = {
  children: PropTypes.object.isRequired,
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(Configuration);
