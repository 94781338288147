import { getTimeDifference } from '../../../lib/dateTimeUtils';

import './PuckContainer.css';

/**
 * A PuckContainer is responsible for aligning a given puck's left positioning
 * @param {Object} props
 * - props.ganttStartDateTime - when the gantt timeline starts
 * - props.puckStartDateTime - when the puck starts
 * - props.customCSSTag - custom CSS tag for container
 * - props.children - the puck to align
 * @returns
 */
const PuckContainer = ({
  ganttStartDateTime,
  puckStartDateTime,
  customCSSTag = '',
  dataCyTag = 'puck-container',
  children,
}) => {
  const timeFromStart = getTimeDifference(ganttStartDateTime, puckStartDateTime, 'minute');
  const containerStyle = {
    position: 'absolute',
    left: `calc(${timeFromStart} * var(--time-scale-minute))`,
  };

  return (
    <div
      className={`puck-container${!!customCSSTag ? ` ${customCSSTag}` : ''}`}
      style={containerStyle}
      data-cy={dataCyTag}
    >
      {children}
    </div>
  );
};

export default PuckContainer;
