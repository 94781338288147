import React from 'react';
import './AddViewButton.css';
import { ReactComponent as AddViewIcon } from '../../../../assets/images/add-view-icon.svg';

const AddViewButton = ({ onClickHandler = () => {}, disabled = false }) => {
  const onClickAddViewButton = () => {
    onClickHandler();
  };

  return (
    <div className={`add-view-button-container ${disabled ? ' disabled' : ''}`} onClick={onClickAddViewButton}>
      <AddViewIcon className="add-view-icon add-btn" data-cy="add-view-btn" />
    </div>
  );
};

export default AddViewButton;
