import { PreferenceType, UserPreferenceState } from '../types';
import { ThemeMode, PuckSize } from '../../lib/constants';

const defaultTheme = { theme: ThemeMode.CLASSIC };
const defaultPuckSize = { puckSize: PuckSize.X_LARGE };
const defaultTimelineHours = { timelineHours: '12' };
const defaultGanttViewScaling = { numberOfAircrafts: 20, hoursAfter: 32, hoursBefore: 4 };

const defaultLoadingState = {
  roleAssignments: null,
  theme: defaultTheme,
  puckSize: defaultPuckSize,
  timelineHours: defaultTimelineHours,
  loading: true,
};

const parsePreference = (userPreferences, preferenceType, defaultValue) => {
  const preference = userPreferences.find((u) => u.preferenceType === preferenceType);
  if (preference) {
    try {
      return JSON.parse(preference.userContent);
    } catch (e) {
      console.error(`Unable to parse saved ${preferenceType} preference`, e);
    }
  }
  return defaultValue;
};

const userPreferencesReducer = (state, action) => {
  switch (action.type) {
    case UserPreferenceState.NONE:
      return {
        roleAssignments: null,
        theme: defaultTheme,
        puckSize: defaultPuckSize,
        timelineHours: defaultTimelineHours,
        ganttViewScaling: defaultGanttViewScaling,
        loading: false,
      };
    case UserPreferenceState.RETRIEVING:
      return defaultLoadingState;
    case UserPreferenceState.RETRIEVED: {
      const userPreferences = action.payload.userPreferences || [];
      return {
        roleAssignments: parsePreference(userPreferences, PreferenceType.ROLE_ASSIGNMENTS, null),
        theme: parsePreference(userPreferences, PreferenceType.THEME, defaultTheme),
        puckSize: parsePreference(userPreferences, PreferenceType.PUCK_SIZE, defaultPuckSize),
        timelineHours: parsePreference(userPreferences, PreferenceType.TIMELINE_HOURS, defaultTimelineHours),
        ganttViewScaling: parsePreference(userPreferences, PreferenceType.GANTT_VIEW_SCALING, defaultGanttViewScaling),
        loading: false,
      };
    }
    case UserPreferenceState.UPDATING:
      return defaultLoadingState;
    case UserPreferenceState.UPDATING_SILENT:
      return state;
    case UserPreferenceState.ERROR:
      return { ...state, loading: false, error: true };
    case UserPreferenceState.UPDATED:
      const updatedState = action.payload;
      return {
        roleAssignments: updatedState.roleAssignments,
        theme: updatedState.theme,
        puckSize: updatedState.puckSize,
        timelineHours: updatedState.timelineHours,
        ganttViewScaling: updatedState.ganttViewScaling,
        loading: false,
      };
    default:
      return state;
  }
};

export default userPreferencesReducer;
