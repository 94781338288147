import React, { useRef, useEffect, useState, useMemo, createRef, useCallback } from 'react';
import {
  getGanttScaleCssClassName,
  getTimelineStartDateTime,
  getTimelineEndDateTime,
  getRowTimes,
  getLineHeight,
} from './ganttHelpers';
import LineOfFlight from '../LineOfFlight/LineOfFlight';
import { useUserPreferencesData } from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import './GanttChart.css';
import '../../../styles/ganttScale.css';
import { ScrollLocalStorageElementId, Treatment, RemInPixels, NoDataMessage } from '../../../lib/constants';
import { getTimeDifference, getDatetimeUtcNowString } from '../../../lib/dateTimeUtils';
import { setComponentScroll, getComponentScroll, convertRemToPixels } from '../../../lib/utils';
import debounce from 'lodash/debounce';
import TimelineContainer from './TimelineContainer/TimelineContainer';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import LoadingIndicator from '../../Shared/LoadingIndicator/LoadingIndicator';
import GanttChartVirtualizer from './GanttChartVirtualizer/GanttChartVirtualizer';
import { useGanttConfig } from '../../../hooks/useGanttConfig/useGanttConfig';
import { useCrosshairStore } from '../../../hooks/useCrosshairStore/useCrosshairStore';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';
import { useSelectedItemDispatch } from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import NoDataMessageHeader from '../NoDataMessageHeader/NoDataMessageHeader';
import { useNotificationUpdate } from '../../../contexts/NotificationsContext/NotificationsContext';
import { ToastType } from '../../Shared/NotificationToast/NotificationToast';
import AircraftLabel from './AircraftLabel/AircraftLabel';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import usePrevious from '../../../hooks/usePrevious/usePrevious';
import SearchOverlay from '../SearchFlightNumber/SearchOverlay/SearchOverlay';
import { useFocusedFlightLegStore } from '../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import { TailNumberIndicatorTypes } from './AircraftLabel/TailNumberIndicators';
import CrosshairContainer from '../CrosshairMode/CrosshairContainer/CrosshairContainer';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const defaultNowLinePosition = 4;

const isCollapsingRowsEnabled = (isSwapModeActive, swapModeCollapsingRowsFlag, collapsingRowsFlag) => {
  return (!isSwapModeActive || swapModeCollapsingRowsFlag) && collapsingRowsFlag;
};

const getHoursToDisplay = (enableEnhancedScalingFlag, hoursBefore, hoursAfter, timelineHours) => {
  return enableEnhancedScalingFlag ? hoursBefore + hoursAfter : timelineHours;
};

/**
 * GanttChart component
 */
const GanttChart = ({
  startDate,
  endDate,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
  showHeader = true,
}) => {
  // custom hook for accessing Gantt scaling settings.
  const { state: userPreferencesState } = useUserPreferencesData();
  const { isSwapModeActive } = useSwapModeStore();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [aircraftLabelWidth, setAircraftLabelWidth] = useState(null);
  const [ganttRowHeightPx, setGanttRowHeightPx] = useState(0);
  const [startDateRange, setStartDateRange] = useState(startDate);
  const [endDateRange, setEndDateRange] = useState(endDate);
  const { isFocused, focusedFlightLeg, highlightFlightLeg } = useFocusedFlightLegStore();
  const { clearSelectedFlightDetails } = useSelectedItemDispatch();
  const [scrollStartPosition, setScrollStartPosition] = useState(0);

  let prevScrollPositions = getComponentScroll(ScrollLocalStorageElementId.GANTT_CHART);
  const previousFilterDates = usePrevious([startDate, endDate]);
  // @deprecated - (BA) useFeatureFlag hook instead of obsolete useTreatments
  const { showFeature } = useFeatureFlag();
  const virtualizeFlag = showFeature(Treatment.GANTT_VIRTUALIZATION);
  const collapsingRowsFlag = showFeature(Treatment.COLLAPSING_ROWS);
  const swapModeCollapsingRowsFlag = showFeature(Treatment.SWAP_MODE_COLLAPSING_ROWS);
  const tailNumberIndicatorsFlag = showFeature(Treatment.TAIL_NUMBER_INDICATORS);
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);

  const collapsingRowsEnabled = isCollapsingRowsEnabled(
    isSwapModeActive,
    swapModeCollapsingRowsFlag,
    collapsingRowsFlag,
  );

  const { loading: loadingGanttConfig, ganttConfig, errors } = useGanttConfig();

  const tailNumberIndicators = useMemo(() => {
    const tailNumberIndicators = {};
    if (tailNumberIndicatorsFlag && Object.keys(ganttConfig).length) {
      Object.keys(ganttConfig).forEach((ac) => {
        tailNumberIndicators[ac] = {
          [TailNumberIndicatorTypes.ETOPS_INDICATOR]: ganttConfig[ac][TailNumberIndicatorTypes.ETOPS_INDICATOR],
          [TailNumberIndicatorTypes.PLUG]: ganttConfig[ac][TailNumberIndicatorTypes.PLUG],
          [TailNumberIndicatorTypes.WIFI_INDICATOR]: ganttConfig[ac][TailNumberIndicatorTypes.WIFI_INDICATOR],
          [TailNumberIndicatorTypes.CAT_STATUS_INDICATOR]:
            ganttConfig[ac][TailNumberIndicatorTypes.CAT_STATUS_INDICATOR],
          [TailNumberIndicatorTypes.MISSING_CERTIFICATES_INDICATOR]:
            ganttConfig[ac][TailNumberIndicatorTypes.MISSING_CERTIFICATES_INDICATOR],
        };
      });
    }
    return tailNumberIndicators;
  }, [ganttConfig, tailNumberIndicatorsFlag]);

  // Track page view
  const { trackPageView } = useAppInsightsContext();

  useEffect(() => {
    trackPageView('GanttViewPage');
  }, [trackPageView]);

  const rowTimes = useMemo(() => {
    if (!focusedFlightLeg && collapsingRowsEnabled && Object.keys(ganttConfig).length) {
      const updatedRowTimes = getRowTimes(ganttConfig, startDate, endDate);
      return updatedRowTimes;
    } else {
      return {};
    }
  }, [ganttConfig]);

  const showRows = useMemo(() => {
    if (!focusedFlightLeg && collapsingRowsEnabled && Object.keys(rowTimes).length && startDateRange && endDateRange) {
      const updatedShowRows = {};
      Object.keys(rowTimes).forEach((aircraft) => {
        if (rowTimes[aircraft].length === 1) {
          updatedShowRows[aircraft] = [true];
          return;
        }
        rowTimes[aircraft].forEach((row) => {
          if (
            dayjs(row.startTime).isSameOrAfter(endDateRange, 'minute') ||
            dayjs(row.endTime).isSameOrBefore(startDateRange, 'minute')
          ) {
            updatedShowRows[aircraft] = [...(updatedShowRows[aircraft] ? updatedShowRows[aircraft] : []), false];
          } else {
            updatedShowRows[aircraft] = [...(updatedShowRows[aircraft] ? updatedShowRows[aircraft] : []), true];
          }
        });
      });
      return updatedShowRows;
    } else {
      return {};
    }
  }, [startDateRange, endDateRange, rowTimes]);

  // Notification toast for ground events error
  const updateNotification = useNotificationUpdate();

  useEffect(() => {
    if (errors.groundEvents && !errors.flights) {
      const message =
        'Unable to retrieve ground event information at this time.\nPlease refresh or contact ITS Service Desk.';
      updateNotification(ToastType.ERROR, message, true, false);
    }
    if (errors.groundEvents && errors.flights) {
      clearSelectedFlightDetails();
    }
  }, [errors.groundEvents, errors.flights, updateNotification, clearSelectedFlightDetails]);

  const aircraftList = useMemo(() => Object.keys(ganttConfig), [ganttConfig]);

  /**
   * Boolean representing whether the gantt chart should display a no data message
   */
  const showNoDataMessage = useMemo(() => {
    return !loadingGanttConfig && (aircraftList.length === 0 || (errors.flights && errors.groundEvents));
  }, [loadingGanttConfig, aircraftList, errors.flights, errors.groundEvents]);

  /**
   * @returns the message to display in the NoDataMessageHeader
   */
  const getNoDataMessage = () => {
    if (errors.flights && errors.groundEvents) {
      return NoDataMessage.FLIGHTS_AND_GROUND_EVENTS_ERROR;
    } else if (errors.flights) {
      return NoDataMessage.FLIGHTS_ERROR;
    } else {
      return NoDataMessage.FILTER_YIELDS_NO_DATA;
    }
  };

  const ganttChartContainerRef = useRef(null);
  const timelineContainerRef = createRef();

  // useCrosshairStore hook
  const { isCrosshairActive } = useCrosshairStore();

  // handler for setting crosshair-active
  const crosshairactiveHandler = () => (isCrosshairActive ? 'crosshair-active' : '');
  // handler for setting swap-mode-active
  const swapModeActiveHandler = () => (isSwapModeActive ? 'swap-mode-active' : '');

  /**
   * Number of hours that needs to be displayed on the gantt
   */
  const hoursToDisplay = getHoursToDisplay(
    enableEnhancedScalingFlag,
    userPreferencesState.ganttViewScaling.hoursBefore,
    userPreferencesState.ganttViewScaling.hoursAfter,
    userPreferencesState.timelineHours.timelineHours,
  );

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const scrollTop = prevScrollPositions?.scrollTop;
    prevScrollPositions = null;
    setComponentScroll(ScrollLocalStorageElementId.GANTT_CHART, scrollTop, 0);
  }, [userPreferencesState?.timelineHours?.timelineHours, userPreferencesState?.ganttViewScaling]);
  /**
   * Returns a memoized function to handle screen width changes
   */
  const handleScreenResize = useMemo(
    () =>
      debounce(() => {
        setScreenWidth(window.innerWidth);
      }, 500),
    [],
  );

  // Disable context menu while crosshair is active
  useEffect(() => {
    if (isCrosshairActive) {
      document.oncontextmenu = () => false;
    } else {
      document.oncontextmenu = null;
    }
    return () => (document.oncontextmenu = null);
  }, [isCrosshairActive]);

  /**
   * Side-effect to remove and cancel timeline resizing after gantt is unmounted
   */
  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      handleScreenResize.cancel();
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize]);

  /**
   * Returns the width (rem) of one hour of time on the gantt that
   * fits the number of hours, hoursToDisplay, on the current gantt screen
   *
   * oneHourRemWidth - one hour in rem
   * oneHourRemWidth - 60 minutes in rem
   */
  const [oneHourRemWidth, oneMinuteRemWidth] = useMemo(() => {
    if (aircraftLabelWidth) {
      const ganttWidth = screenWidth / RemInPixels.ONE_REM_IN_PX;
      const ganttContentWidth = ganttWidth - aircraftLabelWidth;
      return [ganttContentWidth / hoursToDisplay, ganttContentWidth / hoursToDisplay / 60];
    }
    return [null, null];
  }, [hoursToDisplay, aircraftLabelWidth, screenWidth]);

  /**
   * Returns loading for all necessary values needed to render the gantt
   */
  const loading = useMemo(() => {
    const finishedLoading =
      aircraftLabelWidth !== null &&
      hoursToDisplay !== null &&
      oneHourRemWidth !== null &&
      oneMinuteRemWidth !== null &&
      !loadingGanttConfig &&
      (!collapsingRowsEnabled || aircraftList.length === 0 || focusedFlightLeg || Object.keys(showRows).length > 0);

    return !finishedLoading;
  }, [
    aircraftLabelWidth,
    hoursToDisplay,
    oneHourRemWidth,
    oneMinuteRemWidth,
    loadingGanttConfig,
    ganttConfig,
    showRows,
    collapsingRowsEnabled,
  ]);

  useEffect(() => {
    if (loading && scrollStartPositionSet) {
      setScrollStartPositionSet(false);
    }
  }, [loading]);

  /**
   * Saves CSS width and height values of gantt to be used for dynamically calculating
   * other element's CSS values relative to scaling, window size, etc.
   * - Saves the aircraft label's width to be used in calculating the width of the gantt
   * - Saves gantt row height to be used for virtualizing gantt chart
   */
  useEffect(() => {
    if (ganttChartContainerRef?.current) {
      const element = ganttChartContainerRef.current;
      const aircraftWidth = tailNumberIndicatorsFlag
        ? window.getComputedStyle(element).getPropertyValue('--aircraft-container-width-with-indicators')
        : window.getComputedStyle(element).getPropertyValue('--aircraft-container-width');
      //const aircraftWidth = window.getComputedStyle(element).getPropertyValue('--aircraft-container-width');
      const ganttHeight = window.getComputedStyle(element).getPropertyValue('--gantt-row-height');
      setGanttRowHeightPx(convertRemToPixels(ganttHeight));
      setAircraftLabelWidth(parseFloat(aircraftWidth));
    }
  }, [ganttChartContainerRef, userPreferencesState.puckSize.puckSize]);

  /**
   * Assigns CSS properties for other components to scale to the timeline width
   */
  useEffect(() => {
    if (oneHourRemWidth && oneMinuteRemWidth) {
      document.documentElement.style.setProperty('--time-scale-hour', `${oneHourRemWidth}rem`);
      document.documentElement.style.setProperty('--time-scale-minute', `${oneMinuteRemWidth}rem`);
    }
  }, [oneHourRemWidth, oneMinuteRemWidth]);

  // DOM reference to .bottom-content div
  const bottomContentRef = useRef(null);

  // DOM reference to .gantt-content div
  const [ganttContentRef, setGanttContentRef] = useState({ current: null });

  const updateGanttContentRef = useCallback((ref) => {
    if (ref !== null) {
      setGanttContentRef({ current: ref });
    }
  }, []);

  const nowLineHeight = useMemo(() => {
    let updatedNowLineHeight = 0;
    if (!focusedFlightLeg && collapsingRowsEnabled && showRows && Object.keys(showRows).length > 0) {
      Object.keys(showRows).forEach((aircraft) => {
        updatedNowLineHeight += showRows[aircraft].reduce((acc, val) => (val ? acc + 1 : acc), 0);
      });
    } else {
      Object.keys(ganttConfig).forEach((aircraft) => {
        updatedNowLineHeight += ganttConfig[aircraft].totalRows;
      });
    }
    return updatedNowLineHeight;
  }, [collapsingRowsEnabled, showRows, ganttConfig, focusedFlightLeg]);

  // Start and end date times of the gantt chart timeline
  const timelineStartDateTime = getTimelineStartDateTime(startDate, ganttConfig);
  const timelineEndDateTime = getTimelineEndDateTime(endDate, ganttConfig);
  const nowUtcMinutesFromStart = getTimeDifference(timelineStartDateTime, getDatetimeUtcNowString());
  // Number of total hours to show on the timeline
  // Set to either the duration of the dataset (pucks) or the timeline scale setting
  const additionalHours =
    enableEnhancedScalingFlag &&
    (hoursToDisplay > getTimeDifference(timelineStartDateTime, timelineEndDateTime, 'hours') ||
      getTimeDifference(timelineStartDateTime, timelineEndDateTime, 'hours') >= 72)
      ? 24
      : 0;

  const timelineHours = Math.max(
    getTimeDifference(timelineStartDateTime, timelineEndDateTime, 'hours'),
    parseInt(hoursToDisplay) + parseInt(additionalHours),
  );

  /**
   * Boolean representing whether the now line needs to be displayed on the screen.
   */
  const showNowLine = useMemo(() => {
    return nowUtcMinutesFromStart < timelineHours * 60 && nowUtcMinutesFromStart > 0;
  }, [timelineHours, nowUtcMinutesFromStart]);

  const [scrollStartPositionSet, setScrollStartPositionSet] = useState(false);

  const extendedhoursBefore = Math.max(
    userPreferencesState?.ganttViewScaling?.hoursBefore - Math.floor(nowUtcMinutesFromStart / 60),
    0,
  );

  const calculateExtendedStartTimeHours = (showNowLine, enableEnhancedScalingFlag, extendedhoursBefore) => {
    if (showNowLine && enableEnhancedScalingFlag) {
      // +1 for the if statement
      return extendedhoursBefore;
    }
    return 0;
  };
  const extendedStartTimeHours = calculateExtendedStartTimeHours(
    showNowLine,
    enableEnhancedScalingFlag,
    extendedhoursBefore,
  );

  /**
   * @returns the style object for the now line
   */
  const getNowLineStyle = () => {
    if (enableEnhancedScalingFlag && extendedStartTimeHours != 0) {
      return {
        left: `calc(${nowUtcMinutesFromStart + extendedStartTimeHours * 60} * var(--time-scale-minute))`,
        height: `calc(${nowLineHeight} * var(--gantt-row-height))`,
        minHeight: '100%',
      };
    } else {
      return {
        left: `calc(${nowUtcMinutesFromStart} * var(--time-scale-minute))`,
        height: `calc(${nowLineHeight} * var(--gantt-row-height))`,
        minHeight: '100%',
      };
    }
  };

  /**
   * In pixels, the now line left position
   */
  const nowLineLeftScrollPosition = useMemo(() => {
    setScrollStartPositionSet(false);
    let nowLinePosition = defaultNowLinePosition;
    if (showNowLine) {
      if (
        enableEnhancedScalingFlag &&
        (userPreferencesState?.ganttViewScaling?.hoursBefore != 0 ||
          userPreferencesState?.ganttViewScaling?.hoursAfter != 0)
      ) {
        nowLinePosition = userPreferencesState?.ganttViewScaling?.hoursBefore;
      }
      const fourHoursInPx = oneHourRemWidth * RemInPixels.ONE_REM_IN_PX * nowLinePosition;
      return oneMinuteRemWidth * nowUtcMinutesFromStart * RemInPixels.ONE_REM_IN_PX - fourHoursInPx;
    } else {
      return 0;
    }
  }, [
    oneMinuteRemWidth,
    oneHourRemWidth,
    showNowLine,
    nowUtcMinutesFromStart,
    userPreferencesState,
    userPreferencesState?.ganttViewScaling,
  ]);

  /**
   * Side-effect to reset the gantt auto-scrolling flag if the filter dates have changed
   */
  useEffect(() => {
    const filterDatesHaveChanged = previousFilterDates?.[0] !== startDate || previousFilterDates?.[1] !== endDate;
    if (filterDatesHaveChanged) {
      setScrollStartPositionSet(false);
    }
  }, [startDate, endDate, previousFilterDates]);

  const handleAutoScroll = (scrollLeft) => {
    bottomContentRef.current.scrollLeft = scrollLeft;
    if (bottomContentRef.current.scrollLeft + 1 >= scrollLeft) {
      setScrollStartPositionSet(true);
      setScrollStartPosition(1);
    } else if (scrollStartPosition < 5) {
      setScrollStartPosition(scrollStartPosition + 1);
    }
  };

  /**
   * Side-effect to auto-scroll the gantt chart
   */
  useEffect(() => {
    const shouldAutoScroll =
      !scrollStartPositionSet &&
      !loading &&
      (nowLineLeftScrollPosition > 0 || prevScrollPositions != null) &&
      !!bottomContentRef?.current;
    if (shouldAutoScroll) {
      if (prevScrollPositions == null || !scrollStartPosition) {
        handleAutoScroll(nowLineLeftScrollPosition);
      } else {
        if (!virtualizeFlag) {
          bottomContentRef.current.scrollTop = prevScrollPositions.scrollTop;
        }
        handleAutoScroll(prevScrollPositions.scrollLeft);
      }
    }
  }, [
    loading,
    nowLineLeftScrollPosition,
    virtualizeFlag,
    prevScrollPositions,
    scrollStartPositionSet,
    scrollStartPosition,
  ]);

  /**
   * Callback to synchronous scroll of the gantt chart content and the timeline
   * @param {*} e
   */
  const handleScroll = useCallback(
    (e) => {
      if (e.target.className === 'bottom-content' && timelineContainerRef?.current) {
        timelineContainerRef.current.scrollLeft = e.target.scrollLeft;
        setComponentScroll(ScrollLocalStorageElementId.GANTT_CHART, e.target.scrollTop, e.target.scrollLeft);
      }
    },
    [timelineContainerRef],
  );

  useEffect(() => {
    const shouldScroll =
      userPreferencesState?.ganttViewScaling?.hoursBefore > nowUtcMinutesFromStart / 60 &&
      enableEnhancedScalingFlag &&
      nowLineLeftScrollPosition;
    if (shouldScroll) {
      handleAutoScroll(nowLineLeftScrollPosition);
    }
  }, [userPreferencesState?.ganttViewScaling?.hoursBefore]);
  /**
   * Returns the height in pixels of a given aicraft row
   * @param {Number} aircraftIndex - index of nth line of flight row
   * @returns height in pixels
   */
  const getLineOfFlightHeight = useCallback(
    (aircraftIndex) => {
      const aircraft = aircraftList[aircraftIndex];
      return getLineHeight(showRows[aircraft], ganttConfig[aircraft], collapsingRowsEnabled) * ganttRowHeightPx;
    },
    [aircraftList, ganttRowHeightPx, showRows, ganttConfig, collapsingRowsEnabled],
  );

  const getFocusedFlightLegIndex = useMemo(() => {
    if (!isFocused && focusedFlightLeg) {
      const acKeys = Object.keys(ganttConfig);
      return acKeys.findIndex((ac) => ac === focusedFlightLeg?.aircraft);
    }
    return undefined;
  }, [ganttConfig, focusedFlightLeg, isFocused]);

  /**
   * Given an aircraft registration, uses gantt data to render the line of flight
   * @param {string} aircraft - aircraft registration
   * @returns LineOfFlight component within container
   */
  const renderLineOfFlight = useCallback(
    (aircraft) => {
      const lineOfFlightConfig = ganttConfig[aircraft];
      const style = {
        width: `calc(${timelineHours} * var(--time-scale-hour))`,
        height: `calc(${getLineHeight(
          showRows[aircraft],
          ganttConfig[aircraft],
          !focusedFlightLeg && collapsingRowsEnabled,
        )} * var(--gantt-row-height))`,
        lineHeight: `calc(${getLineHeight(
          showRows[aircraft],
          ganttConfig[aircraft],
          !focusedFlightLeg && collapsingRowsEnabled,
        )} * var(--gantt-row-height))`,
      };
      return (
        <div
          className={`${crosshairactiveHandler()} ${swapModeActiveHandler()} gantt-row aircraft-row`}
          data-cy={`aircraft-row-${aircraft}`}
          key={`aircraft-row-${aircraft}`}
          style={style}
        >
          <LineOfFlight
            aircraftRegistration={aircraft}
            lineOfFlightConfig={lineOfFlightConfig}
            ganttStartTime={timelineStartDateTime}
            extendedStartTimeHours={extendedStartTimeHours}
            ganttEndTime={timelineEndDateTime}
            openDetailPane={openDetailPane}
            isPaneOpen={isPaneOpen}
            summaryPanelMode={summaryPanelMode}
            handleChangeActivityKey={handleChangeActivityKey}
            oneHourRemWidth={oneHourRemWidth}
            hoursDisplay={hoursToDisplay}
            showRows={showRows[aircraft]}
            collapsingRowsFlag={collapsingRowsEnabled}
            focusedFlightLeg={focusedFlightLeg}
          />
        </div>
      );
    },
    [ganttConfig, timelineStartDateTime, timelineEndDateTime, hoursToDisplay, oneHourRemWidth, showRows],
  );

  /**
   * Returns the i-th element (aircraft registration) from aircraftList
   */
  const getAircraftKeyByIndex = useCallback((i) => aircraftList[i], [aircraftList]);

  /**
   * Given an aircraft registration, usse gantt data to render an aircraft label
   * @param {string} aircraft - aircraft registration
   * @returns Aircraft label component
   */
  const renderAircraftAxisLabel = useCallback(
    (aircraft) => {
      const lineOfFlight = ganttConfig[aircraft];

      return (
        <AircraftLabel
          aircraft={aircraft}
          airline={lineOfFlight.airline}
          subfleetType={lineOfFlight.fleetType}
          totalRows={getLineHeight(
            showRows[aircraft],
            ganttConfig[aircraft],
            collapsingRowsEnabled && !focusedFlightLeg,
          )}
          className={`${crosshairactiveHandler()} ${swapModeActiveHandler()}`}
          key={`aircraft-label-${aircraft}`}
          tailNumberIndicators={tailNumberIndicators[aircraft]}
          tailNumberIndicatorsFlag={tailNumberIndicatorsFlag}
        />
      );
    },
    [ganttConfig, showRows, collapsingRowsEnabled],
  );

  /**
   * The crosshairs element that is displayed on the Gantt chart.
   * @type {React.ReactNode}
   */
  const crosshairs = (
    <CrosshairContainer
      ref={{
        ganttContentRef,
        bottomContentRef,
      }}
      nowLineHeight={getNowLineStyle().height}
      prevScrollPositions={prevScrollPositions}
      showHeader={showHeader}
    />
  );

  const onDateTimeChanged = useCallback((data) => {
    if (data.type === 'start') {
      setStartDateRange(data.value);
    }
    if (data.type === 'end') {
      setEndDateRange(data.value);
    }
  });

  let aircraftAxisClasses = 'aircraft-axis';
  if (tailNumberIndicatorsFlag) {
    aircraftAxisClasses += ' tail-number-indicators';
  }

  let content;

  if (loading) {
    content = <LoadingIndicator />;
  } else if (showNoDataMessage) {
    content = <NoDataMessageHeader message={getNoDataMessage()} />;
  } else {
    const aircraftAxisContent = virtualizeFlag ? (
      <GanttChartVirtualizer
        count={aircraftList.length}
        renderFn={renderAircraftAxisLabel}
        estimateRowHeightFn={getLineOfFlightHeight}
        getItemKeyFn={getAircraftKeyByIndex}
        scrollToOffset={prevScrollPositions?.scrollTop}
        scrollElementRef={bottomContentRef}
        scrollToFocusedFlightLegIndex={getFocusedFlightLegIndex}
        id="aircraft-axis"
        dataCyTag="aircraft-axis-virtualizer"
        showRows={showRows}
      />
    ) : (
      aircraftList.map((aircraft) => renderAircraftAxisLabel(aircraft))
    );

    const ganttContent = virtualizeFlag ? (
      <GanttChartVirtualizer
        count={aircraftList.length}
        renderFn={renderLineOfFlight}
        estimateRowHeightFn={getLineOfFlightHeight}
        getItemKeyFn={getAircraftKeyByIndex}
        scrollToOffset={prevScrollPositions?.scrollTop}
        scrollElementRef={bottomContentRef}
        scrollToFocusedFlightLegIndex={getFocusedFlightLegIndex}
        id="gantt"
        dataCyTag="gantt-chart-virtualizer"
        showRows={showRows}
      />
    ) : (
      aircraftList.map((aircraft) => renderLineOfFlight(aircraft))
    );

    const searchOverlay = highlightFlightLeg ? (
      <SearchOverlay
        aircraft={focusedFlightLeg.aircraft}
        showRows={showRows}
        ganttConfig={ganttConfig}
        collapsingRowsFlag={collapsingRowsEnabled}
        timelineHours={timelineHours}
      />
    ) : null;

    content = (
      <>
        <div className="top-content">
          <TimelineContainer
            timelineStartDateTime={timelineStartDateTime}
            extendedStartTimeHours={extendedStartTimeHours}
            timelineHours={timelineHours}
            oneHourRemWidth={oneHourRemWidth}
            timeZoneStartDate={startDate}
            ref={timelineContainerRef}
            hoursToDisplay={hoursToDisplay}
            onDateTimeChanged={collapsingRowsEnabled ? onDateTimeChanged : false}
            tailNumberIndicatorsFlag={tailNumberIndicatorsFlag}
          />
        </div>
        <div
          className="bottom-content"
          data-cy="gantt-chart-bottom-content"
          onScroll={handleScroll}
          ref={bottomContentRef}
        >
          {searchOverlay}
          {crosshairs}
          <div className={aircraftAxisClasses} data-cy="aircraft-axis">
            {aircraftAxisContent}
          </div>
          <div id="gantt-content" className="gantt-content" data-cy="gantt-content" ref={updateGanttContentRef}>
            <div className="test-overlay" />
            {showNowLine && <div id="now-line" style={getNowLineStyle()} data-cy="now-line" />}
            {ganttContent}
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      id="gantt-chart-container"
      className={`gantt-chart-container ${getGanttScaleCssClassName(userPreferencesState.puckSize.puckSize)}`}
      data-cy="gantt-chart-container"
      ref={ganttChartContainerRef}
    >
      {content}
    </div>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(GanttChart, 'app-insights-tracking-gantt-chart');
