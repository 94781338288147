import FlightInfoTable from '../FlightInfoTable/FlightInfoTable';
import { Grid } from '@material-ui/core';

const FlightInfoTables = ({ flightInfo, trip, date, columnNames }) => {
  return (
    <Grid container spacing={2} style={{ padding: '1rem' }}>
      {Array.from({ length: 8 }).map((_, index) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
          <FlightInfoTable flightInfo={flightInfo} trip={trip} date={date} columnNames={columnNames} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FlightInfoTables;
