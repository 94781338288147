import React, { useState, useEffect } from 'react';
import './ManageViewsDropdown.css';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as ColumnIcon } from '../../../../assets/images/columns-icon.svg';
import { ReactComponent as RowsIcon } from '../../../../assets/images/rows-icon.svg';
import { ReactComponent as CascadeIcon } from '../../../../assets/images/cascade-icon.svg';
import { ReactComponent as GridIcon } from '../../../../assets/images/grid-icon.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/blue-check-mark.svg';
import SingleSelectDropdown from '../../../Shared/Inputs/SingleSelectDropdown/SingleSelectDropdown';
import { ReactComponent as ManageViewsIcon } from '../../../../assets/images/manage-views-icon.svg';

export const CheckOverflow = ({ content }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  useEffect(() => {
    setIsOverflow(content.length > 58);
  }, [content]);

  const truncatedContent = content.length > 58 ? content.slice(0, 58) + '...' : content;

  return (
    <Tooltip
      title={content}
      placement="bottom"
      disableHoverListener={!isOverflow}
      PopperProps={{ style: { zIndex: 1700 } }}
    >
      <div className="static-text">{truncatedContent}</div>
    </Tooltip>
  );
};

const createViewOption = (key, content) => ({
  key,
  name: (
    <div className="available-view">
      <div className="check-icon">
        <CheckIcon />
      </div>
      <CheckOverflow content={content} />
    </div>
  ),
});

const availableViews = [
  createViewOption('view1', "1. Today's Rotation "),
  createViewOption('view2', '2. Lorem ipsum dolor sit amet,  consectetur adipiscing elit, sed do eiusmod tem'),
  createViewOption('view3', '3. SEA, PDX | SFO, LAX'),
];

function organizeViews(key, IconComponent) {
  return {
    key: key,
    name: (
      <div className="layout-option">
        <div className="layout-option-icon">
          <IconComponent />
        </div>{' '}
        {key.charAt(0).toUpperCase() + key.slice(1)}{' '}
      </div>
    ),
  };
}

const viewOptions = [
  organizeViews('columns', ColumnIcon),
  organizeViews('rows', RowsIcon),
  organizeViews('cascade', CascadeIcon),
  organizeViews('grid', GridIcon),
];

const ManageViewsDropdown = ({ onClickHandler = () => {}, disabled = false }) => {
  const onClickManageViewDropdown = () => {
    onClickHandler();
  };

  return (
    <div
      className={`manage-views-container ${disabled ? 'disabled' : ''}`}
      data-cy="manage-views-container"
      onClick={onClickManageViewDropdown}
    >
      <SingleSelectDropdown
        customIcon={<ManageViewsIcon />}
        displayList={viewOptions}
        className="manage-views-dropdown"
        dataCyTag="manage-views-dropdown"
        divider={<div className="view-layout-divider"> {true}</div>}
        displayList2={availableViews}
      ></SingleSelectDropdown>
    </div>
  );
};

export default ManageViewsDropdown;
