import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import FlightActionMenu from '../../FlightActionMenu/FlightActionMenu';
import './ModernFlightPuck.css';
import { IrropsCode, Treatment } from '../../../../lib/constants';
import { jsonEqual } from '../../../../lib/utils';
import { displayFlightNumber } from '../../../../lib/displayUtils';
import { getPuckRenderStat, getFlightPuckClassNames } from '../../GanttChart/ganttHelpers';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';

/**
 * ModernFlightPuck component renders a puck to be displayed in the gantt chart
 * @param {Object} flightLeg - a flight leg object to be rendered
 * @param {function} onPuckClick
 */
const ModernFlightPuck = ({ data, openDetailPane, isPaneOpen, summaryPanelMode, handleChangeActivityKey }) => {
  //Get puck's display statistics
  const puckRenderStat = getPuckRenderStat(data);

  const selectedFlightDetails = useSelectedItemStore();
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();

  const [showFlightNumber, setShowFlightNumber] = useState(true);
  const flightNumberRef = useRef(null);
  const [showFlightActionMenu, setShowFlightActionMenu] = useState(false);
  const [showOverLay, setShowOverLay] = useState(false);
  // feature flag
  useFeatureFlag();
  const { isReady } = useContext(SplitContext);
  // @deprecated - (BA) useFeatureFlag hook instead of obsolete useTreatments
  const treatments = useTreatments([Treatment.FLIGHT_EQUIPMENT_CHANGE]);
  const equipmentChangeFlag = isReady && treatments[Treatment.FLIGHT_EQUIPMENT_CHANGE]?.treatment === 'on';

  React.useEffect(() => {
    // hide flight number if there's text overflow
    const timerRef = setTimeout(() => {
      const element = flightNumberRef?.current;
      if (element && element.scrollWidth > element.clientWidth) {
        setShowFlightNumber(false);
      } else {
        setShowFlightNumber(true);
      }
    }, 1000);
    return () => clearTimeout(timerRef);
  }, []);

  const handleClick = () => {
    if (selectedFlightDetails.data?.flightLegKey !== data.flightLegKey) {
      const newFlightDetails = {
        data: data,
        isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
        isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
      };

      updateSelectedFlightDetails(newFlightDetails);
    }
  };

  const handleMouseEnter = () => {
    setShowFlightActionMenu(true);
  };

  const puckStyle = {
    width: `calc(${puckRenderStat.puckDuration} * var(--time-scale-minute))`,
  };

  //Left block of the puck
  const leftBlock = (
    <div
      className={puckRenderStat.leftBlockStyle}
      style={{
        width: `${
          puckRenderStat.durationBetweenDepartureAndOff > 0
            ? `calc(${puckRenderStat.durationBetweenDepartureAndOff} * var(--time-scale-minute))`
            : '0'
        }`,
      }}
    />
  );

  //Right block of the puck
  let rightBlock = (
    <div
      className={puckRenderStat.rightBlockStyle}
      style={{
        width: `${
          puckRenderStat.durationBetweenOnAndArrival > 0
            ? `calc(${puckRenderStat.durationBetweenOnAndArrival} * var(--time-scale-minute))`
            : '0'
        }`,
      }}
    ></div>
  );

  const shortPuckFlag = !showFlightNumber ? 'short-puck-flag' : '';

  const flightActionMenuContainer = (
    <div className={`flight-action-menu ${shortPuckFlag}`}>
      <FlightActionMenu
        flightLeg={data}
        openDetailPane={openDetailPane}
        isPaneOpen={isPaneOpen}
        summaryPanelMode={summaryPanelMode}
        handleChangeActivityKey={handleChangeActivityKey}
        isWatchFlight={data.isWatchFlight}
        overLayHandler={(e) => setShowOverLay(e)}
      />
    </div>
  );

  const selected = selectedFlightDetails.data?.flightLegKey === data.flightLegKey;
  const selectLabel = selected ? 'selected-puck' : '';

  return (
    <div
      className={`flightpuck-container ${selectLabel} ${shortPuckFlag} ${showOverLay ? 'visible-overlay' : ''}`}
      data-cy="flightpuck-container"
    >
      <div
        className={getFlightPuckClassNames(
          ['flight-puck'],
          data,
          selected,
          isPaneOpen,
          false,
          false,
          equipmentChangeFlag,
        )}
        data-cy={`${data.irropsCode === IrropsCode.CANCELLED_FLIGHT ? 'canceled-' : ''}flight-puck-${
          data.flightLegKey
        }`}
        style={puckStyle}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
      >
        {leftBlock}
        {showFlightNumber && (
          <div className="flight-number" ref={flightNumberRef}>
            {displayFlightNumber(data)}
          </div>
        )}
        {rightBlock}
        {showFlightActionMenu && flightActionMenuContainer}
      </div>
    </div>
  );
};

ModernFlightPuck.propTypes = {
  data: PropTypes.shape({
    flightLegKey: PropTypes.number.isRequired,
    flightNumber: PropTypes.number.isRequired,
    orig: PropTypes.string.isRequired,
    dest: PropTypes.string.isRequired,
    departure: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
    aircraft: PropTypes.string.isRequired,
  }).isRequired,
  openDetailPane: PropTypes.func.isRequired,
  isPaneOpen: PropTypes.bool.isRequired,
};

export default React.memo(ModernFlightPuck, (prevProps, nextProps) => {
  return (
    jsonEqual(prevProps.data, nextProps.data) &&
    prevProps.isPaneOpen === nextProps.isPaneOpen &&
    prevProps.summaryPanelMode === nextProps.summaryPanelMode
  );
});
