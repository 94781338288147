import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextInput from '../../Shared/Inputs/TextInput/TextInput';
import Modal from '../../Shared/Modal/Modal';
import Button from '../../Shared/Button/Button';
import './SaveConfigModal.css';

/**
 * SaveConfigModal component is used to display the modal for the scalability feature.
 * @param {funcation}  onClose - Function to close the modal.
 * @param {boolean}  showModal - Boolean value to show/hide the modal
 * @returns  Modal component with the body and footer buttons.
 */
const SaveConfigModal = ({ onSave, onClose, showModal = false }) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [inputVal, setInputVal] = useState('');

  const onChangeHandler = (inputText) => {
    setInputVal(inputText);

    if (inputText.length > 0) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };
  const handleSave = (event) => {
    onSave(inputVal);
  };
  const handleCancel = (event) => {
    onClose();
  };
  // Body of the modal with the counter components for hoursBefore, hoursAfter and numberOfAircrafts values
  const body = (
    <div className="saveconfig-modal-row" data-cy="saveconfig-modal">
      <Grid container item xs={12} className="saveconfig-grid-container">
        <Grid item xs={12}>
          <TextInput
            placeholderText=""
            dataCyTag="saveconfig-filename-text"
            onChange={onChangeHandler}
            maxLengthValue={100}
            value={inputVal}
            autoFocus={true}
          />
        </Grid>
      </Grid>
    </div>
  );

  // Footer buttons for the modal component with Apply and cancel buttons
  const formButtons = [
    <Button
      key="cancel"
      variant="secondary"
      className="saveconfig-footer-button cancel"
      tabIndex="0"
      onClick={handleCancel}
      data-cy="nav-saveconfig-cancel-btn"
    >
      Cancel
    </Button>,
    <Button
      key="save"
      variant="primary"
      className="saveconfig-footer-button save"
      tabIndex="0"
      isDisabled={isSaveDisabled}
      onClick={handleSave}
      data-cy="nav-saveconfig-save-btn"
    >
      Save
    </Button>,
  ];

  // Modal component with the body and footer buttons for the scalability feature modal
  return (
    <Modal
      focusId="focusId"
      title="Save as"
      body={body}
      show={showModal}
      size="saveconfig-dialog"
      onHide={onClose}
      customCSSTag="saveconfig-dialog"
      dataCyTag="saveconfig-dialog"
      footerButtons={formButtons}
      bottomLine={false}
      bottomDivider={false}
    />
  );
};

export default SaveConfigModal;
