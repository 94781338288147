import React, { useState, useContext, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SummaryActivityLogTab from './SummaryActivityLogTab/SummaryActivityLogTab';
import SummaryAircraftTab from './SummaryAircraftTab/SummaryAircraftTab';
import SummaryAlertTab from './SummaryAlertTab/SummaryAlertTab';
import SummaryCrewTab from './SummaryCrewTab/SummaryCrewTab';
import SummaryFlightTab from './SummaryFlightTab/SummaryFlightTab';
import SummaryPassengerTab from './SummaryPassengerTab/SummaryPassengerTab';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import { useAppInsightsContext } from '../../../../contexts/AppInsightsContext/AppInsightsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faDownLeftAndUpRightToCenter,
  faAnglesUp,
  faAnglesDown,
} from '@fortawesome/free-solid-svg-icons';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { CSSTransition } from 'react-transition-group';
import { SplitContext } from '@splitsoftware/splitio-react';

import { Treatment, ActiveKeys } from '../../../../lib/constants';
import {
  useSelectedItemStore,
  useSelectedItemDispatch,
} from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { makeStyles } from '@material-ui/core/styles';
import './SummaryDetailPaneContainer.css';
import useFlightLeg from '../../../../hooks/useFlightLeg/useFlightLeg';

const useStyles = makeStyles((theme) => ({
  rightArrow: {
    color: 'var(--gray)',
  },
  boxContent: {
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

const separator = <span data-cy="pane-header-text-separator" className="pane-header-text-separator"></span>;

const SummaryDetailPaneContainer = ({ onClick, activityKey, handleChangeActivityKey, onMaximize, isMaximized }) => {
  const [props] = useState({ show: false });
  const [activeKey, setActiveKey] = useState(activityKey);
  const selectedItem = useSelectedItemStore();
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();
  const { showFeature } = useFeatureFlag();
  const { isReady } = useContext(SplitContext);
  const flightTabsFlag = isReady && showFeature(Treatment.FLIGHT_DETAIL_PANE);
  const aircraftTabFlag = isReady && showFeature(Treatment.FLIGHT_MELDATA);
  const crewOnFlight = isReady && showFeature(Treatment.CREW_ON_FLIGHT);
  const selectedFlightLeg = selectedItem.data;

  const handleOnSelect = (key) => {
    setActiveKey(key);
    handleChangeActivityKey(key);
  };

  const classes = useStyles();
  // Text separator, renders " -> "
  const arrowSeparator = <FontAwesomeIcon className={classes.rightArrow} icon={faArrowRight} />;

  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('FlightPanePage');

  const { getFlightLegSummary } = useFlightLeg(selectedFlightLeg, activeKey === ActiveKeys.Flight);
  const { flightNumber, origin, destination, flightDateAndDay, phaseOfFlight, irropsCode, irregularOperationReason } =
    getFlightLegSummary();

  useEffect(() => {
    let isFlightPanelOpen = activeKey === ActiveKeys.Flight;
    let isActivityTabOpen = activeKey === ActiveKeys.ActivityLog;

    setActiveKey(activityKey);
    updateSelectedFlightDetails({
      data: selectedFlightLeg,
      isFlightPanelOpen: isFlightPanelOpen,
      isActivityTabOpen: isActivityTabOpen,
    });
  }, [activeKey, activityKey, selectedFlightLeg, updateSelectedFlightDetails]);

  return (
    <div className="summary-pane-container" data-cy="summary-pane-container">
      <div className="summary-pane-header" data-cy="summary-pane-header">
        <Grid container>
          <Grid item container sm={12} xs={12} md={6} lg={6}>
            <Grid item xs={11}>
              <div className="summary-pane-header-left" data-cy="summary-pane-header-left">
                {flightDateAndDay}
                {separator}
                {flightNumber}
                {separator}
                {origin} {arrowSeparator} {destination}
                {separator}
                {phaseOfFlight}
                {irropsCode != null && irropsCode != '- -' ? (
                  <span className="summary-pane-header-irropscode" data-cy="summary-pane-header-irropscode">
                    {irropsCode}
                    {irregularOperationReason && <>&nbsp;-&nbsp;{irregularOperationReason}</>}
                  </span>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={1} className="summary-pane-maximize-grid">
              {crewOnFlight && (
                <IconButton className="summary-pane-maximize" aria-label="Maximize summary pane" onClick={onMaximize}>
                  {isMaximized ? <FontAwesomeIcon icon={faAnglesDown} /> : <FontAwesomeIcon icon={faAnglesUp} />}
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid item container sm={12} xs={12} md={6} lg={6} className={classes.boxContent}>
            <div className="summary-pane-header-right" data-cy="summary-pane-header-right">
              <CSSTransition in={props.show} appear={true} timeout={300} classNames="fade">
                <div className="summary-pane-content-tab" data-cy="summary-pane-content-tabs">
                  <Tabs activeKey={activeKey} onSelect={handleOnSelect} id="summary-detail-pane-tabs">
                    <Tab eventKey={ActiveKeys.Flight} title="Flight" data-cy="summary-flightTab">
                      {activeKey === ActiveKeys.Flight && <SummaryFlightTab />}
                    </Tab>
                    {flightTabsFlag && (
                      <Tab eventKey={ActiveKeys.Alerts} title="Alerts" data-cy="AlertsTab">
                        <SummaryAlertTab />
                      </Tab>
                    )}
                    <Tab eventKey={ActiveKeys.ActivityLog} title="Activity Log" data-cy="ActivityLogTab">
                      <SummaryActivityLogTab />
                    </Tab>
                    {flightTabsFlag && (
                      <Tab eventKey={ActiveKeys.Passenger} title="Passenger" data-cy="PassengerTab">
                        <SummaryPassengerTab />
                      </Tab>
                    )}
                    {aircraftTabFlag && (
                      <Tab eventKey={ActiveKeys.Aircraft} title="Aircraft" data-cy="AircraftTab">
                        <SummaryAircraftTab />
                      </Tab>
                    )}
                    {crewOnFlight && (
                      <Tab eventKey={ActiveKeys.Crew} title="Crew" data-cy="CrewTab">
                        <SummaryCrewTab selectedItem={selectedItem} />
                      </Tab>
                    )}
                  </Tabs>
                  <div className="icon-bar-down">
                    <FontAwesomeIcon
                      data-cy="summary-panel-contract"
                      className="summary-panel-contract"
                      onClick={onClick}
                      icon={faDownLeftAndUpRightToCenter}
                    />
                  </div>
                </div>
              </CSSTransition>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(SummaryDetailPaneContainer);
