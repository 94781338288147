import { createStore, applyMiddleware, compose } from 'redux';
import throttle from 'lodash/throttle';
import rootReducer from './reducers/rootReducer';
import multipleViewsRootReducer from './reducers/multipleViewsRootReducer';
import { setSessionStorageItem } from '../lib/sessionStorage/sessionStorage';
import { SessionStorageKeys } from '../lib/constants';

/**
 * Persist the store to session storage
 * @param {*} store
 */
const persistStore = (store) => {
  // Save the filter state to session storage
  const { filterReducer } = store.getState();
  if (!filterReducer.isSwapModeFilter) {
    setSessionStorageItem(SessionStorageKeys.FILTER, filterReducer);
  }
};

const configureStore = (initialState, multipleViewsEnabled = false) => {
  // add support for Redux dev tools
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    multipleViewsEnabled ? multipleViewsRootReducer : rootReducer,
    initialState,
    composeEnhancers(applyMiddleware()),
  );

  if (!multipleViewsEnabled) {
    store.subscribe(
      throttle(() => {
        persistStore(store);
      }, 1000),
    );
  }

  return store;
};

export default configureStore;
