import React from 'react';
import { getLineHeight } from '../../GanttChart/ganttHelpers';
import './SearchOverlay.css';

const getFirstOverlayHeight = (aircraft, showRows, ganttConfig, collapsingRowsFlag) => {
  let total = 0;
  for (let ac in ganttConfig) {
    if (ac === aircraft) {
      break;
    }
    const totalRows = getLineHeight(showRows[ac], ganttConfig[ac], collapsingRowsFlag);
    total += totalRows > 0 ? totalRows : 1;
  }
  return total;
};

const getSecondOverlayHeight = (aircraft, showRows, ganttConfig, collapsingRowsFlag) => {
  const acKeys = Object.keys(ganttConfig);
  let total = 0;
  const acIndex = acKeys.findIndex((ac) => ac === aircraft);
  for (let i = acIndex; i < acKeys.length; i++) {
    const totalRows = getLineHeight(showRows[acKeys[i]], ganttConfig[acKeys[i]], collapsingRowsFlag);
    total += totalRows > 0 ? totalRows : 1;
  }
  return total;
};

const firstOverlay = (aircraft, showRows, ganttConfig, collapsingRowsFlag, timelineHours) => {
  const firstOverlayHeight = getFirstOverlayHeight(aircraft, showRows, ganttConfig, collapsingRowsFlag);
  const style = {
    width: `calc(${timelineHours} * var(--time-scale-hour) + var(--aircraft-container-width))`,
    height: `calc(${firstOverlayHeight} * var(--gantt-row-height))`,
    lineHeight: `calc(${firstOverlayHeight} * var(--gantt-row-height))`,
    top: 0,
    left: 0,
  };
  return <div style={style} className="search-overlay first-search-overlay" data-cy="first-search-overlay" />;
};

const secondOverlay = (aircraft, showRows, ganttConfig, collapsingRowsFlag, timelineHours) => {
  const currentLineHeight = getLineHeight(showRows[aircraft], ganttConfig[aircraft], collapsingRowsFlag);
  const secondOverlayHeight = getSecondOverlayHeight(aircraft, showRows, ganttConfig, collapsingRowsFlag);
  const firstOverlayHeight = getFirstOverlayHeight(aircraft, showRows, ganttConfig, collapsingRowsFlag);
  const style = {
    width: `calc(${timelineHours} * var(--time-scale-hour) + var(--aircraft-container-width))`,
    height: `calc(${secondOverlayHeight - currentLineHeight} * var(--gantt-row-height))`,
    lineHeight: `calc(${secondOverlayHeight - currentLineHeight} * var(--gantt-row-height))`,
    top: `calc(${firstOverlayHeight + currentLineHeight} * var(--gantt-row-height))`,
    left: 0,
  };
  return <div style={style} className="search-overlay second-search-overlay" data-cy="second-search-overlay" />;
};

const SearchOverlay = ({ aircraft, showRows, ganttConfig, collapsingRowsFlag, timelineHours }) => (
  <>
    {firstOverlay(aircraft, showRows, ganttConfig, collapsingRowsFlag, timelineHours)}
    {secondOverlay(aircraft, showRows, ganttConfig, collapsingRowsFlag, timelineHours)}
  </>
);

export default React.memo(SearchOverlay);
