import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  FlightView,
  Treatment,
  KeyCodes,
  EntitlementNames,
  SessionStorageKeys,
  ScrollLocalStorageElementId,
} from '../../../lib/constants';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { ReactComponent as ChevronUpIcon } from '../../../assets/images/Header-chevron-up-icon.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/images/Header-chevron-down-icon.svg';
import { FilterModal } from '../FilterModal/FilterModal';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import AddFlight from '../../AddFlight/AddFlight';
import './FlightNavigationBar.css';
import Popover from '@material-ui/core/Popover';
import DynamicCheckbox from '../../Shared/Inputs/DynamicCheckbox/DynamicCheckbox';
import { useFilterStore, useFilterDispatch } from '../../../hooks/useFilterStore/useFilterStore';
import {
  useHideStore,
  useHideCancelledFilterDispatch,
} from '../../../hooks/useHideCancelledFlightsStore/useHideCancelledFlightsStore';
import { makeStyles } from '@material-ui/core/styles';
import { VerticalSpacingZoom } from '../VerticalSpacingZoom/VerticalSpacingZoom';
import TimelineHoursDropdown from '../TimelineHoursDropdown/TimelineHoursDropdown';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';
import { SwapButton } from '../SwapMode/SwapButton/SwapButton';
import CrosshairButton from '../CrosshairMode/CrosshairButtons/CrosshairButtons';
import SearchFlightNumber from '../SearchFlightNumber/SearchFlightNumber';
import AddViewButton from '../MultipleViews/AddViewButton/AddViewButton';
import ManageViewsDropdown from '../MultipleViews/ManageViewsDropdown/ManageViewsDropdown';
import ScalingButton from '../ScalingButton/ScalingButton';
import { useWindowManagerDispatch } from '../../../hooks/useWindowManagerStore/useWindowManagerStore';
import NavButtons from './NavButtons/NavButtons';
import PuckFilterButton from '../PucksFilter/PuckFilterButton';
import GanttSortMenu from '../GanttSortMenu/GanttSortMenu';
import { clearComponentScrollState } from '../../../lib/utils';
import MultiViewSearchFlightNumber from '../SearchFlightNumber/MultiViewSearchFlightNumber';

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: '.8rem',
  },
}));

export const FlightNavigationBar = ({
  onShowHideHeader,
  showNavbar,
  viewType,
  currentActiveId,
  clearSort = () => {},
}) => {
  const styleClass = useStyles();

  // Get feature flag for clear filter button and flight irrops
  const { showFeature } = useFeatureFlag();
  const showSwapFeatureFlag = showFeature(Treatment.FLIGHT_SWAP);
  const showScalingButtonFeatureFlag = showFeature(Treatment.SCALING_BUTTON);
  const showSearchFlightNumber = showFeature(Treatment.SEARCH_FLIGHTNUMBER);
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);
  const showOverlappingPucks = showFeature(Treatment.OVERLAPPING_PUCKS);
  const showSortMenu = showFeature(Treatment.SORT_MENU);

  //Hook to get the initial filter store object
  const { isDefaultFilter } = useFilterStore();
  const { hideCancelledFlights } = useHideStore();
  const { updateHideCancelledFilter } = useHideCancelledFilterDispatch();
  const { addViewWindow, updateViewWindow } = useWindowManagerDispatch();

  const { updateFilter, clearFilter } = useFilterDispatch();

  //Hide canceled flights flag
  const [showFilterModal, setShowFilterModal] = useState(false);

  // useSwapModeStore
  const { isSwapModeActive } = useSwapModeStore();
  const searchFlightNumRef = useRef();

  //Hide Canceled Flight popover
  const [anchorPopover, setAnchorPopover] = useState(null);

  let flightsStyle = '';

  if (isSwapModeActive) {
    flightsStyle = 'disabled';
  } else if (viewType === FlightView.TABLE_VIEW) {
    flightsStyle = 'selected';
  }

  /**
   * Event handler func for when the filter button is clicked.
   * @param {Object} filter - filters to apply
   * @param {Boolean} clearSort - whether to clear the saved sort filter or not
   */
  const onFilterClick = useCallback(
    (filter) => {
      // clear localStorage items related to Scroll positions on GanttChart and FlightList Table view
      clearComponentScrollState([
        ScrollLocalStorageElementId.GANTT_CHART,
        ScrollLocalStorageElementId.FLIGHTLIST_TABLE,
      ]);

      // dispatch a filter update in redux store
      updateFilter(filter);
    },
    [updateFilter],
  );

  const onFilterClearClick = () => {
    // clear localStorage items related to Scroll positions on GanttChart and FlightList Table view
    clearComponentScrollState([ScrollLocalStorageElementId.GANTT_CHART, ScrollLocalStorageElementId.FLIGHTLIST_TABLE]);
    // clear filters from redux store
    clearFilter();

    clearSort();
  };

  //Event trigger when Hide Canceled Flight popover closes
  const onCloseHideCanceledFlight = () => {
    setAnchorPopover(null);
  };

  // Event trigger on click event of Hide Canceled Flight icon
  const handleHideCanceledFlightSelect = (event) => {
    setAnchorPopover(event.currentTarget);
  };

  const handleOnFilterClick = (e) => {
    if (searchFlightNumRef.current) {
      searchFlightNumRef.current.resetSearchNum();
    }
    onFilterClick(e);
  };

  const handleClearButtonClick = () => {
    if (searchFlightNumRef.current) {
      searchFlightNumRef.current.resetSearchNum();
    }
    onFilterClearClick();
  };

  // Event trigger on change event of Hide Canceled Flight checkbox
  const onChangeHideCanceledFlight = () => {
    let hide = !hideCancelledFlights;
    window.sessionStorage.setItem(
      SessionStorageKeys.HIDE_CANCELLED_FLIGHTS,
      JSON.stringify({ hideCancelledFlights: hide }),
    );
    setAnchorPopover(null);
    updateHideCancelledFilter({ hideCancelledFlights: hide });
  };

  useEffect(() => {
    if (searchFlightNumRef.current) {
      searchFlightNumRef.current.resetSearchNum();
    }
  }, [isSwapModeActive]);
  // Event trigger on handleKeyDown for flight list icon
  const handleKeyDown = (event) => {
    if (event.keyCode === KeyCodes.ENTER && isSwapModeActive) {
      event.preventDefault();
    }
  };

  // CLEAR Button Clicked
  const clearFilters = () => {
    handleClearButtonClick();
  };

  const clearFilterStyle = isDefaultFilter || isSwapModeActive ? 'disabled' : '';
  const filterButtonStyle = isSwapModeActive ? 'disabled' : '';

  const onClickAddViewButton = () => {
    addViewWindow();
  };

  const onViewTypeIconClick = (viewType) => {
    updateViewWindow(currentActiveId, { viewType });
  };

  return (
    <div className="flight-list-new-filters flight-nav-bar-container" data-cy="flight-nav-bar-container">
      {isSwapModeActive && showMultipleViews && (
        <div data-cy="flight-nav-bar-swap-mode-border" className="flight-nav-bar-swap-mode-border" />
      )}
      <div className="left-container">
        <NavButtons
          viewType={viewType}
          isSwapModeActive={isSwapModeActive}
          onViewTypeIconClick={onViewTypeIconClick}
          handleKeyDown={handleKeyDown}
          showMultipleViews={showMultipleViews}
        />
        {showSearchFlightNumber && showMultipleViews && (
          <MultiViewSearchFlightNumber ref={searchFlightNumRef} viewType={viewType} />
        )}
        {showSearchFlightNumber && !showMultipleViews && (
          <SearchFlightNumber ref={searchFlightNumRef} viewType={viewType} />
        )}
        <div
          data-cy="filter-button"
          className={`nav-filter-button flight-nav-bar-item ${filterButtonStyle}`}
          onClick={() => setShowFilterModal(true)}
        >
          <FontAwesomeIcon data-cy="filter-icon" className={`filter nav-icon ${filterButtonStyle}`} icon={faFilter} />
        </div>

        <div
          data-cy="clear-filter-flights-button"
          className={`nav-filter-button flight-nav-bar-item clear-filter ${clearFilterStyle}`}
          onClick={clearFilters}
        >
          Clear Filter
        </div>
        {viewType === FlightView.GANTT_VIEW && showSortMenu && (
          <GanttSortMenu filterButtonStyle={filterButtonStyle} flightsStyle={flightsStyle} />
        )}

        <div
          data-cy="hide-canceled-flights-button"
          className="nav-filter-button flight-nav-bar-item"
          onClick={handleHideCanceledFlightSelect}
        >
          <FontAwesomeIcon data-cy="canceled-flights-icon" className="eye icon nav-icon" icon={faEyeSlash} />
        </div>

        {showOverlappingPucks && (
          <PuckFilterButton
            className="nav-filter-button flight-nav-bar-item"
            onClick={() => {
              alert('Puck Filter');
            }}
          />
        )}

        <Popover
          id={anchorPopover ? 'hide-canceled-flights-popover' : undefined}
          anchorEl={anchorPopover}
          open={anchorPopover ? true : false}
          onClose={onCloseHideCanceledFlight}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          className={styleClass.popover}
          data-cy="hide-canceled-flights-popover"
        >
          <span className="label">Hide</span>
          <DynamicCheckbox
            dataCyTag="hide-canceled-flights-checkbox"
            label="Canceled Flights"
            type={'checkbox'}
            customCSSTag={'hide-canceled-flights'}
            checked={hideCancelledFlights}
            onChange={onChangeHideCanceledFlight}
          />
        </Popover>
        {showFilterModal && (
          <FilterModal
            showFilterModal={showFilterModal}
            setShowFilterModal={() => setShowFilterModal(false)}
            onFilterClick={handleOnFilterClick}
            onFilterClearClick={handleClearButtonClick}
          />
        )}
      </div>
      <div className="right-container">
        {showMultipleViews && (
          <>
            <AddViewButton disabled={isSwapModeActive} onClickHandler={onClickAddViewButton} />
            <ManageViewsDropdown disabled={isSwapModeActive} />
          </>
        )}
        {viewType !== FlightView.TABLE_VIEW && (
          <>
            <CrosshairButton />
            {showSwapFeatureFlag && (
              <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.SWAP, EntitlementNames.SUPPORTVALIDATION]}>
                <SwapButton />
              </AocAuthAccessCheck>
            )}
            {showScalingButtonFeatureFlag && <ScalingButton dataCyTag="scaling-button-icon" />}
            {!showScalingButtonFeatureFlag && (
              <>
                <VerticalSpacingZoom dataCyTag="gantt-zoom-icon" />
                <TimelineHoursDropdown dataCyTag="gantt-timeline-dropdown" />
              </>
            )}
          </>
        )}
        {/* Render Add Flight component */}

        <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.ADDFLIGHT, EntitlementNames.SUPPORTVALIDATION]}>
          <div className="add-flight-div" data-cy="add-flight-container">
            <AddFlight isShowAddBtn={true} />
          </div>
        </AocAuthAccessCheck>
        <div className="nav-hideshow" data-cy="nav-hideshow">
          <div className="nav-hideshow-icon">
            {showNavbar === true ? (
              <ChevronUpIcon data-cy="chevron-up-logo" className="collapse-chevron" onClick={onShowHideHeader} />
            ) : (
              <ChevronDownIcon
                data-cy="chevron-down-logo"
                className="collapse-chevron"
                onClick={onShowHideHeader}
              ></ChevronDownIcon>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

FlightNavigationBar.propTypes = {
  onShowHideHeader: PropTypes.func,
  showNavbar: PropTypes.bool,
  viewType: PropTypes.string,
  onFilterClick: PropTypes.func,
  currentActiveId: PropTypes.number,
  clearSort: PropTypes.func,
};

export default withAppInsightsTracking(FlightNavigationBar);
