import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../../../Shared/Button/Button';
import Modal from '../../../Shared/Modal/Modal';
import './RenameViewModal.css';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import TextInput from '../../../Shared/Inputs/TextInput/TextInput';
/**
 * The RenameViewModal component is to rename a specific view from the different views available.
 * @param {Object} props -
 *  showConfirmPopup - show/hide confirm modal
 *  handleCancelClick - callback func when cancel button is pressed.
 *  handleApplyClick - callback func when apply button is pressed.
 *  handleOnKeyPress - callback func when any key is pressed in the modal.
 *  handleHideClick - callback func when close the rename modal.
 *  dataCyTag- data-cy Tag for button and rename modal
 *  placeholder- placeholder for the text area
 * @returns RenameViewModal component
 */

const RenameViewModal = ({
  showConfirmPopup,
  handleCancelClick,
  handleApplyClick,
  handleOnKeyPress,
  handleHideClick,
  placeholder,
}) => {
  const [userComments, setUserComments] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (!showConfirmPopup) {
      setUserComments(placeholder);
      setErrorMessage('');
      setIsDisabled(false);
    }
  }, [showConfirmPopup, placeholder]);

  const handleTextInputChange = (value) => {
    if (value.length > 75) {
      setErrorMessage('Name must be less than 75 characters long.');
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      setUserComments(value);
      setErrorMessage('');
    }
  };

  /* footer buttons for RenameView popup modal. */
  const formButtons = [
    <Button
      key={'rename-modal-cancel'}
      variant="secondary"
      tabIndex="0"
      data-cy="rename-view-cancel-button"
      onClick={handleCancelClick}
      onKeyPress={handleOnKeyPress}
      className={'rename-view-cancel-button'}
    >
      Cancel
    </Button>,
    <Button
      key={'rename-modal-apply'}
      variant="primary"
      tabIndex="0"
      data-cy="rename-view-apply-button"
      onClick={handleApplyClick}
      onKeyPress={handleOnKeyPress}
      isDisabled={isDisabled || userComments.length === 0 || userComments === placeholder}
      className={'rename-view-apply-button'}
    >
      Apply
    </Button>,
  ];

  /* build modal body for RenameView popup modal. */
  const popupHTML = (
    <Fragment>
      <div className="rename-view-popup-modal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              value={userComments}
              onChange={handleTextInputChange}
              dataCyTag="rename-view-textarea"
              maxLength={75}
              warningType={errorMessage ? 'error' : 'none'}
              warningMessage={errorMessage}
            />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );

  return (
    <Modal
      show={showConfirmPopup}
      title={'Rename View'}
      body={popupHTML}
      size={'thin rename-view-popup'}
      footerButtons={formButtons}
      onHide={handleHideClick}
      customCSSTag={'rename-view-popup'}
      dataCyTag="rename-view-popup"
      bottomDivider={false}
    />
  );
};

export default withAppInsightsTracking(RenameViewModal);
