import React, { useState, useMemo, useCallback } from 'react';
import DatePicker from '../../../Shared/Inputs/DatePicker/DatePicker';
import ChipInput from '../../../Shared/Inputs/ChipInput/ChipInput';
import ChipInputDropdown from '../../../Shared/Inputs/ChipInputDropdown/ChipInputDropdown';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MultiSelectDropdown from '../../../Shared/Inputs/MultiSelectDropdown/MultiSelectDropdown';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import {
  Treatment,
  AssignmentFilter,
  PhaseOfFlightFilter,
  AirlineFilter,
  AlertFilter,
  SubFleetFilter,
  KeyCodes,
  AssignmentType,
  AssignmentLabels,
  phaseOfFlightItems,
  airlineItems,
  assignmentListItems,
} from '../../../../lib/constants';
import BrickContainer from '../../../Shared/BrickContainer/BrickContainer';
import { useAirportsQuery } from '../../../../hooks/useAirportsQuery/useAirportsQuery';
import { useReferenceAlertsQuery } from '../../../../hooks/useReferenceQuery/useReferenceAlertsQuery';
import { useAircraftInformationQuery } from '../../../../hooks/useAircraftInformationQuery/useAircraftInformationQuery';
import { useRoleAssignmentContext } from '../../../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import './FilterContainer.css';
import { multiSelectDropdownUtils, chipInputUtils } from '../../../../lib/inputUtils';
import { smartSearch, focusNextElement } from '../../../../lib/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';

dayjs.extend(utc);

const selectAllPlaceholderText = 'Select All';

// Export for unit test
export const getNewPacificDates = (initialFilter, timeZone, date, propUpdate) => {
  const newDateObj = dayjs(date).tz(timeZone, true).startOf('day');

  if (propUpdate === 'startDate') {
    const isStartDateAfterEndDate = newDateObj.isAfter(initialFilter.endDate);

    const newFilterData = {
      ...initialFilter,
      startDate: newDateObj,
      // End date should be changed to selected start date end of day when it is after end date
      endDate: isStartDateAfterEndDate
        ? dayjs(newDateObj).tz(initialFilter.timezone, true).endOf('day')
        : initialFilter.endDate,
    };
    return newFilterData;
  }

  if (propUpdate === 'endDate') {
    const isEndDateBeforeStartDate = newDateObj.isBefore(initialFilter.startDate);

    const newFilterData = {
      ...initialFilter,
      // Start date should be changed to selected end date when it is before start date
      startDate: isEndDateBeforeStartDate ? newDateObj : initialFilter.startDate,
      // End date should always be end of day of the selected date
      endDate: dayjs(newDateObj).tz(initialFilter.timezone, true).endOf('day'),
    };
    return newFilterData;
  }
  throw new Error('propUpdate value is mandatory in getNewPacificDates method');
};
/**
 * The FilterContainer holds all the inputs for the filter modal.
 * It's a controlled component, so the filter state is always passed in and changes
 * are always called back up.
 * @param {Object} initialFilter - the filters to display
 * @param {bool} isDisabled - whether or not the filters are disabled
 * @param {Function} onFilterUpdate - callback when the filter has been changed
 * @param {array} aircraftList - list of aircrafts for the aircraft filter
 * @param {string} timeZone - the user's timezone
 * @returns
 */
const FilterContainer = ({
  initialFilter,
  isDisabled,
  onFilterUpdate,
  aircraftList = [],
  timeZone,
  isClearFilter,
  handleClearFilter,
}) => {
  const msdUtils = multiSelectDropdownUtils();
  const { isLoading: isLoadingAirportsData, data: airportsData, error: airportsDataError } = useAirportsQuery();
  const { isLoading: alertsLoading, data: rawAlertTypes } = useReferenceAlertsQuery();
  const { isLoading: fleetsLoading, data: rawFleetTypes } = useAircraftInformationQuery('');
  const [airports, setAirports] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);
  const [originTextInput, setOriginTextInput] = useState('');
  const [destinationTextInput, setDestinationTextInput] = useState('');
  const [flightNumberTextInput, setFlightNumberTextInput] = useState('');
  const [aircraftTextInput, setAircraftTextInput] = useState('');
  const [fleetTypeplaceholder, setFleetTypeplaceholder] = useState('');
  const [alertTypesplaceholder, setAlertTypesplaceholder] = useState('');
  const [alertTypes, setAlertTypes] = useState([]);
  const [fleetTypes, setFleetTypes] = useState([]);

  const formatDateOnly = 'YYYY-MM-DD';
  const stationRegex = /^[a-zA-Z0-9]{3}$/;
  const lettersRegexMax3Letters = /^[a-zA-Z0-9]{0,3}$/;
  const lettersRegexMax6Letters = /^[a-zA-Z0-9]{0,6}$/;
  const numbersRegexMax4Digits = /^[0-9]{1,4}$/;
  const aircraftRegex = /^[a-zA-Z0-9]{6}$/;
  const maxDateProps = {};
  const chipDLUtils = chipInputUtils();

  const [phaseOfFlightSelectAllChecked, setPhaseOfFlightSelectAllChecked] = useState(true);
  const [airlineSelectAllChecked, setAirlineSelectAllChecked] = useState(true);
  const [fleetTypeSelectAllChecked, setFleetTypeSelectAllChecked] = useState(true);
  const [fleetTypeSelectItem, setFleetTypeSelectItem] = useState('');
  const [minScheduledOperatingDate] = useState(dayjs().format(formatDateOnly));
  const maxScheduledOperatingDate = dayjs(minScheduledOperatingDate).add(7, 'day').format(formatDateOnly);
  // feature flag
  const { showFeature } = useFeatureFlag();
  const datePickerMaxDateFlag = showFeature(Treatment.DATE_PICKER_MAXDATE);

  // RoleAssignment hook
  const { roleAssignments } = useRoleAssignmentContext();
  let defaultAssignment = roleAssignments.role.defaultAssignmentGroupTypeName;

  let defaultAssignmentVal = null;

  if (defaultAssignment == 'FLIGHT') {
    defaultAssignmentVal = AssignmentFilter.MY_FLIGHTS;
  } else if (defaultAssignment == 'AIRCRAFT') {
    defaultAssignmentVal = AssignmentFilter.MY_AIRCRAFT;
  }

  //Check feature flag for datePickerMaxDate & set propos datePicker
  if (!datePickerMaxDateFlag) {
    maxDateProps.maxDate = maxScheduledOperatingDate;
  }

  /**
   * @description get dropdown items list based selected values param.
   * @param {*} items
   * @param {*} selectedItems
   * @param {*} selectAllValue
   * @param {*} isSelectAll
   * @returns dropdown items with changes.
   */
  const getItemList = useCallback(
    (items, selectedItems, selectAllValue, isSelectAll) => {
      if (selectedItems.length === 1 && selectedItems[0] === selectAllValue) {
        if (isSelectAll) {
          return msdUtils.checkAllItems(items);
        } else {
          return msdUtils.uncheckAllItems(items);
        }
      } else {
        return msdUtils.setMultipleItemsSelection(items, selectedItems, true, fleetTypeSelectItem);
      }
    },
    [msdUtils],
  );

  const getPhaseOfFlightValues = useMemo(() => {
    if (!phaseOfFlightSelectAllChecked && isClearFilter) {
      setPhaseOfFlightSelectAllChecked(true);
    }
    const newItems = getItemList(
      phaseOfFlightItems,
      initialFilter.flightPhase,
      PhaseOfFlightFilter.ALL_FLIGHTS,
      phaseOfFlightSelectAllChecked,
    );
    const newPlaceholder = msdUtils.getPlaceholdertext(newItems, [selectAllPlaceholderText]);
    return { items: newItems, placeholder: newPlaceholder };
  }, [initialFilter.flightPhase, getItemList, msdUtils, phaseOfFlightSelectAllChecked]);

  const getAirlineValues = useMemo(() => {
    if (!airlineSelectAllChecked && isClearFilter) {
      setAirlineSelectAllChecked(true);
    }
    const newItems = getItemList(airlineItems, initialFilter.airline, AirlineFilter.ALL, airlineSelectAllChecked);
    const newPlaceholder = msdUtils.getPlaceholdertext(newItems, [selectAllPlaceholderText]);
    return { items: newItems, placeholder: newPlaceholder };
  }, [initialFilter.airline, msdUtils, getItemList, airlineSelectAllChecked]);

  const getAssignmentItemList = useCallback((items, selectedItems) => {
    let newVal = items;
    items.map((item) => {
      if (selectedItems?.includes(item.key)) {
        if (item.name === AssignmentLabels.ALL_AIRCRAFT || item.name == AssignmentLabels.MY_FLIGHT) {
          // All Aircraft and My Flights chosen  - Disable rest of CHECKBOXES
          newVal = msdUtils.checkFlights(newVal, item.name, true);
        } else if (item.name === AssignmentLabels.MY_AIRCRAFT) {
          // My Aircraft chosen - disable My Flights
          newVal = msdUtils.checkAircrafts(newVal, item.name, true);
        } else if (item.name === AssignmentLabels.ALL_FLIGHTS) {
          // All Flights chosen - disable My Flights
          newVal = msdUtils.checkAircrafts(newVal, item.name, true);
        }
      }
    });
    return newVal;
  });

  // AssignmentList values
  const assignmentListValues = useMemo(() => {
    const newAssignmentItems = getAssignmentItemList(assignmentListItems, initialFilter.assignmentList);
    const newPlaceholder = msdUtils.getPlaceholdertext(newAssignmentItems, [AssignmentType.ALL_AIRCRAFT]);
    return { items: newAssignmentItems, placeholder: newPlaceholder };
  }, [initialFilter.assignmentList, getAssignmentItemList, msdUtils]);

  /**
   * @description useMemo hook handling to set the Fleettype and fleet type place holder states.
   * @returns
   */
  useMemo(() => {
    let newPlaceholder = '';
    if (!fleetsLoading && rawFleetTypes) {
      if (!fleetTypeSelectAllChecked && isClearFilter) {
        setFleetTypeSelectAllChecked(true);
      }
      const requiredFleet = [
        {
          key: SubFleetFilter.ALL,
          name: selectAllPlaceholderText,
          checked: fleetTypeSelectAllChecked ? true : false,
          isFocus: false,
        },
      ];
      // Create a set of unique FleetType values due to certain
      // Fleets appearing across multiple airlines.
      const uniqueFleetTypes = [...new Set(rawFleetTypes.map((f) => f.fleetType))];
      const dataset = uniqueFleetTypes.map((fleetType) => {
        return { key: fleetType, name: fleetType, checked: fleetTypeSelectAllChecked ? true : false, isFocus: false };
      });

      const fleetItems = [...requiredFleet, ...dataset];
      setFleetTypes(fleetItems);
      const newItems = getItemList(fleetItems, initialFilter.fleets, SubFleetFilter.ALL, fleetTypeSelectAllChecked);
      newPlaceholder =
        initialFilter.fleets.includes(SubFleetFilter.ALL) && fleetTypeSelectAllChecked
          ? 'All'
          : msdUtils.getPlaceholdertext(newItems, [selectAllPlaceholderText]);
    } else {
      const newItems = getItemList(fleetTypes, initialFilter.fleets, SubFleetFilter.ALL, fleetTypeSelectAllChecked);
      setFleetTypes(newItems);
      newPlaceholder =
        initialFilter.fleets.includes(SubFleetFilter.ALL) && fleetTypeSelectAllChecked
          ? 'All'
          : msdUtils.getPlaceholdertext(newItems, [selectAllPlaceholderText]);
    }
    setFleetTypeplaceholder(newPlaceholder);
    return;
  }, [initialFilter.fleets, fleetsLoading, rawFleetTypes]);

  // alertTypes values - useMemo hook handling to set the alertTypes and alertTypes place holder states.
  useMemo(() => {
    let newPlaceholder = '';
    if (!alertsLoading && rawAlertTypes) {
      // Check if the alertTypes are selected and set the default value
      const requiredAlerts = [{ key: AlertFilter.ALL, name: selectAllPlaceholderText, checked: false }];
      const dataset = rawAlertTypes.alertTypes.map((d) => {
        return { key: d.name, name: d.title, checked: false };
      });
      // Create a set of unique alertTypes values due to certain
      const alertItems = [...requiredAlerts, ...dataset];
      let defaultAlerts = [];

      // Check if the alertTypes are selected and set the default value
      if (!initialFilter.alerts.includes(AlertFilter.NO_FILTER)) {
        defaultAlerts = initialFilter.alerts;
      }
      // Get the list of alertTypes with the selected values
      var alertsWithSelection = getItemList(alertItems, defaultAlerts);
      const filteredAlertTypes = msdUtils.filterDropdown(alertsWithSelection, [AlertFilter.SHORT_TURN_TIME]);
      // Set the alertTypes and alertTypes place holder states
      setAlertTypes(filteredAlertTypes);
      newPlaceholder = msdUtils.getPlaceholdertext(alertsWithSelection, [selectAllPlaceholderText]);
    }
    // Set the alertTypes place holder state
    setAlertTypesplaceholder(newPlaceholder);
    return;
  }, [initialFilter.alerts, alertsLoading, rawAlertTypes]);

  // FILTER STARTDATE AND ENDDATE
  const handleDatePickerSelection = (date, propUpdate) => {
    if (dayjs.isDayjs(date)) {
      onFilterUpdate(getNewPacificDates(initialFilter, timeZone, date, propUpdate));
    }
  };

  //STARTDATE AND ENDDATE - Handle OnKeyDown event on DatePicker to prevent any key press
  const handleDatePickerOnKeyDownEvent = (event) => {
    if (event.keyCode === KeyCodes.TAB) {
      let eTarget = null;
      if (event.target.type === 'button') {
        eTarget = event.target?.parentElement?.parentNode?.querySelector('input');
      } else {
        eTarget = event.target;
      }
      focusNextElement(event, eTarget, 'filter-item');
      event.stopPropagation();
    } else {
      event.preventDefault();
    }
  };

  // UPDATE updateAssignmentFilter
  const updateAssignmentFilter = (popList) => {
    const filterValue = msdUtils.getCheckedKeyList(popList);
    const newFilterData = {
      ...initialFilter,
      assignmentList: filterValue.length == 0 ? [defaultAssignmentVal] : filterValue,
    };
    onFilterUpdate(newFilterData);
  };

  // ASSIGNMENT FILTER
  const handleAssignmentCheckChange = (selectedName) => {
    const itemList = getAssignmentItemList(assignmentListItems, initialFilter.assignmentList);
    if (selectedName === AssignmentLabels.ALL_AIRCRAFT || selectedName === AssignmentLabels.MY_FLIGHT) {
      if (msdUtils.isChecked(itemList, selectedName)) {
        let newItms = msdUtils.checkFlights(itemList, selectedName, false);
        updateAssignmentFilter(newItms);
      } else {
        let newItms = msdUtils.checkFlights(itemList, selectedName, true);
        updateAssignmentFilter(newItms);
      }
    } else if (selectedName === AssignmentLabels.MY_AIRCRAFT || selectedName === AssignmentLabels.ALL_FLIGHTS) {
      if (msdUtils.isChecked(itemList, selectedName)) {
        let newItms = msdUtils.checkAircrafts(itemList, selectedName, false);
        updateAssignmentFilter(newItms);
      } else {
        let newItms = msdUtils.checkAircrafts(itemList, selectedName, true);
        updateAssignmentFilter(newItms);
      }
    }
  };

  // PHASE OF FLIGHT FILTER
  const updatePhaseOfFlightFilter = (popList) => {
    const filterValue = msdUtils.getCheckedKeyList(popList);
    const newFilterData = {
      ...initialFilter,
      flightPhase: filterValue.length === 0 ? [PhaseOfFlightFilter.ALL_FLIGHTS] : filterValue,
    };
    onFilterUpdate(newFilterData);
  };

  /**
   * @description handle phase of flight dropdown changes and select and unselect.
   * @param {*} selectedName
   */
  const handlePhaseOfFlightCheckChange = (selectedName) => {
    if (isClearFilter) {
      handleClearFilter();
    }
    const itemList = getItemList(
      phaseOfFlightItems,
      initialFilter.flightPhase,
      PhaseOfFlightFilter.ALL_FLIGHTS,
      phaseOfFlightSelectAllChecked,
    );
    if (selectedName === selectAllPlaceholderText) {
      if (phaseOfFlightSelectAllChecked && msdUtils.isChecked(itemList, selectedName)) {
        let newVal = msdUtils.uncheckAllItems(itemList);
        setPhaseOfFlightSelectAllChecked(false);
        updatePhaseOfFlightFilter(newVal);
      } else {
        setPhaseOfFlightSelectAllChecked(true);
        let newVal = msdUtils.checkAllItems(itemList);
        updatePhaseOfFlightFilter(newVal);
      }
    } else {
      if (msdUtils.isChecked(itemList, selectedName)) {
        if (msdUtils.isChecked(itemList, selectAllPlaceholderText)) {
          var list = msdUtils.setItemSelection(itemList, selectAllPlaceholderText, false);
          let newList = msdUtils.setItemSelection(list, selectedName, false);
          updatePhaseOfFlightFilter(newList);
        } else {
          if (msdUtils.getCheckedCount(itemList) === 1) {
            let newVal = msdUtils.uncheckAllItems(itemList);
            setPhaseOfFlightSelectAllChecked(false);
            updatePhaseOfFlightFilter(newVal);
          } else {
            let newList = msdUtils.setItemSelection(itemList, selectedName, false);
            updatePhaseOfFlightFilter(newList);
          }
        }
      } else {
        if (msdUtils.getCheckedCount(itemList) === itemList.length - 2) {
          let newList = msdUtils.checkAllItems(itemList);
          updatePhaseOfFlightFilter(newList);
        } else {
          let newList = msdUtils.setItemSelection(itemList, selectedName, true);
          updatePhaseOfFlightFilter(newList);
        }
      }
    }
  };

  // AIRLINE FILTER
  const updateAirlineFilter = (popList) => {
    const filterValue = msdUtils.getCheckedKeyList(popList);
    const newFilterData = {
      ...initialFilter,
      airline: filterValue.length === 0 ? [AirlineFilter.ALL] : filterValue,
    };
    onFilterUpdate(newFilterData);
  };
  /**
   * @description handle airline dropdown changes and select and unselect.
   * @param {*} selectedName
   */
  const handleAirlineCheckChange = (selectedName) => {
    if (isClearFilter) {
      handleClearFilter();
    }
    const itemList = getItemList(airlineItems, initialFilter.airline, AirlineFilter.ALL, airlineSelectAllChecked);
    if (selectedName === selectAllPlaceholderText) {
      if (airlineSelectAllChecked && msdUtils.isChecked(itemList, selectedName)) {
        let newVal = msdUtils.uncheckAllItems(itemList);
        setAirlineSelectAllChecked(false);
        updateAirlineFilter(newVal);
      } else {
        let newVal = msdUtils.checkAllItems(itemList);
        setAirlineSelectAllChecked(true);
        updateAirlineFilter(newVal);
      }
    } else {
      if (msdUtils.isChecked(itemList, selectedName)) {
        if (msdUtils.isChecked(itemList, selectAllPlaceholderText)) {
          var list = msdUtils.setItemSelection(itemList, selectAllPlaceholderText, false);
          let newList = msdUtils.setItemSelection(list, selectedName, false);
          updateAirlineFilter(newList);
        } else {
          if (msdUtils.getCheckedCount(itemList) === 1) {
            let newVal = msdUtils.uncheckAllItems(itemList);
            setAirlineSelectAllChecked(false);
            updateAirlineFilter(newVal);
          } else {
            let newList = msdUtils.setItemSelection(itemList, selectedName, false);
            updateAirlineFilter(newList);
          }
        }
      } else {
        if (msdUtils.getCheckedCount(itemList) === itemList.length - 2) {
          let newList = msdUtils.checkAllItems(itemList);
          updateAirlineFilter(newList);
        } else {
          let newList = msdUtils.setItemSelection(itemList, selectedName, true);
          updateAirlineFilter(newList);
        }
      }
    }
  };

  /**
   * @description handle update sub fleet type filter.
   * @param {any[]} popList selected items list.
   */
  const updateFleetTypeFilter = (popList) => {
    const filterValue = msdUtils.getCheckedKeyList(popList);
    const isAll = filterValue.length > 0 ? filterValue.includes(SubFleetFilter.ALL) : false;
    const newFilterData = {
      ...initialFilter,
      fleets: filterValue.length === 0 || isAll ? [SubFleetFilter.ALL] : filterValue,
    };
    onFilterUpdate(newFilterData);
  };

  /**
   * @description handle fleet type dropdown changes and select and unselect.
   * @param {string} selectedName selected item name.
   */
  const handleFleetTypeCheckChange = (selectedName) => {
    if (isClearFilter) {
      handleClearFilter();
    }
    const itemList = getItemList(fleetTypes, initialFilter.fleets, SubFleetFilter.ALL, fleetTypeSelectAllChecked);
    if (selectedName === selectAllPlaceholderText) {
      if (fleetTypeSelectAllChecked && msdUtils.isChecked(itemList, selectedName)) {
        let newVal = msdUtils.uncheckAllItems(itemList);
        setFleetTypeSelectAllChecked(false);
        updateFleetTypeFilter(newVal);
      } else {
        setFleetTypeSelectAllChecked(true);
        let newVal = msdUtils.checkAllItems(itemList);
        updateFleetTypeFilter(newVal);
      }
    } else {
      setFleetTypeSelectItem(selectedName);
      if (msdUtils.isChecked(itemList, selectedName)) {
        if (msdUtils.isChecked(itemList, selectAllPlaceholderText)) {
          var list = msdUtils.setItemSelection(itemList, selectAllPlaceholderText, false);
          let newList = msdUtils.setItemSelection(list, selectedName, false);
          updateFleetTypeFilter(newList);
        } else {
          let newList = msdUtils.setItemSelection(itemList, selectedName, false);
          updateFleetTypeFilter(newList);
        }
      } else {
        if (msdUtils.getCheckedCount(itemList) === itemList.length - 2) {
          let newList = msdUtils.checkAllItems(itemList);
          updateFleetTypeFilter(newList);
        } else {
          let newList = msdUtils.setItemSelection(itemList, selectedName, true);
          updateFleetTypeFilter(newList);
        }
      }
    }
  };

  // COMMON FUNCTIONS FOR STATION FIELDS BEGIN

  const getFilteredAirports = useCallback(
    (textInput) => {
      if (!isLoadingAirportsData && airportsData != null && airportsData.data.length > 0 && airports.length === 0) {
        var airportsList = airportsData.data.map((airport) => {
          return {
            name: `${airport.airportCode} - ${airport.airportName} (${airport.airportCity}, ${airport.airportState})`,
            value: airport.airportCode,
          };
        });
        setAirports(airportsList);
        return airportsList;
      }
      if (airports.length > 0) {
        let lCasedInput = textInput.toUpperCase();
        return airports.filter((airport) => {
          return airport.name.startsWith(lCasedInput);
        });
      }
    },
    [airports, airportsData, isLoadingAirportsData],
  );

  /**
   * @description validate the text value typed in station input
   * @returns
   */
  const isStationStringValid = (station) => {
    if (station != null && station.match(lettersRegexMax3Letters)) return true;
    return false;
  };

  /**
   * @description validate the station value to be added as a chip
   * @returns
   */
  const isStationChipValid = (station) => {
    if (station != null && station.match(stationRegex)) return true;
    return false;
  };
  // COMMON FUNCTIONS FOR STATION FIELDS END

  // ORIGIN DROPDOWN HANDLERS BEGIN
  const getOriginFilteredAirports = useMemo(() => {
    if (
      (originTextInput == null && originTextInput.length === 0) ||
      isLoadingAirportsData ||
      airportsDataError ||
      !airportsData?.data?.length
    )
      return [];
    return getFilteredAirports(originTextInput);
  }, [originTextInput, airportsData, airportsDataError, isLoadingAirportsData, getFilteredAirports]);

  const handleOriginTextInputChange = (text) => {
    if (isStationStringValid(text)) setOriginTextInput(text);
  };
  const clearOriginText = () => {
    if (originTextInput.length > 0) setOriginTextInput('');
  };
  const handleOriginChipAdd = (chip) => {
    if (!isStationChipValid(chip)) {
      clearOriginText();
      return;
    }

    const newFilterData = {
      ...initialFilter,
      origin: chipDLUtils.addChip(initialFilter.origin, chip.trim().toUpperCase()),
    };
    clearOriginText();
    onFilterUpdate(newFilterData);
  };
  const handleOriginChipDelete = (chip) => {
    if (!isStationStringValid(chip)) return;

    const newFilterData = {
      ...initialFilter,
      origin: chipDLUtils.deleteChip(initialFilter.origin, chip.trim().toUpperCase()),
    };
    onFilterUpdate(newFilterData);
  };
  const handleOriginSuggestionSelect = (chip) => {
    if (!isStationStringValid(chip)) return;
    handleOriginChipAdd(chip);
    clearOriginText();
  };
  /**
   * @description Handles origin chipdropdown click away event. Adds text input as a chip if it is available
   * @returns
   */
  const handleOriginClickAway = () => {
    handleOriginChipAdd(originTextInput);
  };
  // ORIGIN DROPDOWN HANDLERS END

  // DESTINATION DROPDOWN HANDLERS BEGIN
  const getDestinationFilteredAirports = useMemo(() => {
    if (
      (destinationTextInput == null && destinationTextInput.length === 0) ||
      isLoadingAirportsData ||
      airportsDataError ||
      !airportsData?.data?.length
    )
      return [];
    return getFilteredAirports(destinationTextInput);
  }, [destinationTextInput, airportsData, airportsDataError, isLoadingAirportsData, getFilteredAirports]);

  const handleDestinationTextInputChange = (text) => {
    if (isStationStringValid(text)) setDestinationTextInput(text);
  };
  const clearDestinationText = () => {
    if (destinationTextInput.length > 0) setDestinationTextInput('');
  };
  const handleDestinationChipAdd = (chip) => {
    if (!isStationChipValid(chip)) {
      clearDestinationText();
      return;
    }

    const newFilterData = {
      ...initialFilter,
      destination: chipDLUtils.addChip(initialFilter.destination, chip.trim().toUpperCase()),
    };
    clearDestinationText();
    onFilterUpdate(newFilterData);
  };
  const handleDestinationChipDelete = (chip) => {
    if (!isStationStringValid(chip)) return;
    const newFilterData = {
      ...initialFilter,
      destination: chipDLUtils.deleteChip(initialFilter.destination, chip.trim().toUpperCase()),
    };
    onFilterUpdate(newFilterData);
  };
  const handleDestinationSuggestionSelect = (chip) => {
    handleDestinationChipAdd(chip);
    clearDestinationText();
  };

  /**
   * @description Handles destination chipdropdown click away event. Adds text input as a chip if it is available
   * @returns
   */
  const handleDestinationClickAway = () => {
    handleDestinationChipAdd(destinationTextInput);
  };

  // DESTINATION DROPDOWN HANDLERS END

  // FLIGHTNUMBER CHIPINPUT HANDLERS BEGIN

  const isFlightNumberValid = (flightNumber) => {
    if ((flightNumber != null && flightNumber.match(numbersRegexMax4Digits)) || flightNumber === '') return true;
    return false;
  };

  /**
   * @description Handles flight number text key down event (TAB)
   * @returns
   */
  const handleFlightNumberTextKeyDown = (event) => {
    if (event.keyCode === KeyCodes.TAB) handleFlightNumberAdd(flightNumberTextInput);
  };
  const handleFlightNumberTextChange = (flight) => {
    if (isFlightNumberValid(flight)) setFlightNumberTextInput(flight);
  };
  const handleFlightNumberAdd = (chip) => {
    if (chip === '' || !isFlightNumberValid(chip)) return;
    const newFilterData = {
      ...initialFilter,
      flight: chipDLUtils.addChip(initialFilter.flight, chip.trim()),
    };
    setFlightNumberTextInput('');
    onFilterUpdate(newFilterData);
  };

  const handleFlightNumberDelete = (chip) => {
    if (!isFlightNumberValid(chip)) return;
    const newFilterData = {
      ...initialFilter,
      flight: chipDLUtils.deleteChip(initialFilter.flight, chip.trim()),
    };
    onFilterUpdate(newFilterData);
  };
  /**
   * @description Handles flight number chipInput blur event. Adds text input as a chip if it is available
   * @returns
   */
  const handleFlightNumberBlur = () => {
    handleFlightNumberAdd(flightNumberTextInput);
  };
  // FLIGHTNUMBER CHIPINPUT HANDLERS END

  // AIRCRAFT CHIPINPUT HANDLERS BEGIN
  const isAircraftValid = (aircraft) => {
    if (aircraft != null && aircraft.match(lettersRegexMax6Letters)) return true;
    return false;
  };
  /**
   * @description validate the aircraft value to be added as a chip
   * @returns
   */
  const isAircraftChipValid = (aircraft) => {
    if (aircraft != null && aircraft.match(aircraftRegex)) return true;
    return false;
  };

  const getFilteredAircrafts = useCallback(
    (textInput) => {
      if (aircraftList.length > 0 && aircrafts.length === 0) {
        var tailNumbersList = aircraftList.map((aircraft) => {
          return {
            name: `${aircraft}`,
            value: aircraft,
          };
        });
        setAircrafts(tailNumbersList);
        return tailNumbersList;
      }

      if (aircrafts.length > 0 && textInput !== '') {
        return smartSearch(textInput, aircrafts);
      }
    },
    [aircrafts, aircraftList],
  );

  const filteredAircrafts = useMemo(() => {
    if ((aircraftTextInput == null && aircraftTextInput.length === 0) || !aircraftList?.length) return [];
    return getFilteredAircrafts(aircraftTextInput);
  }, [aircraftTextInput, aircraftList, getFilteredAircrafts]);

  const clearAircraftsText = () => {
    if (aircraftTextInput.length > 0) setAircraftTextInput('');
  };

  const handleAircraftsSuggestionSelect = (chip) => {
    handleAircraftChipAdd(chip);
    clearAircraftsText();
  };

  const handleAircraftChipAdd = (chip) => {
    if (chip != null && chip.length === 5) {
      chip = 'N' + chip;
    }

    if (!isAircraftChipValid(chip)) {
      clearAircraftsText();
      return;
    }

    const newFilterData = {
      ...initialFilter,
      aircraft: chipDLUtils.addChip(initialFilter.aircraft, chip.trim().toUpperCase()),
    };
    clearAircraftsText();
    onFilterUpdate(newFilterData);
  };

  const handleAircraftChipDelete = (chip) => {
    if (!isAircraftValid(chip)) return;
    const newFilterData = {
      ...initialFilter,
      aircraft: chipDLUtils.deleteChip(initialFilter.aircraft, chip.trim().toUpperCase()),
    };
    onFilterUpdate(newFilterData);
  };

  const handleAircraftTextChange = (aircraft) => {
    if (isAircraftValid(aircraft)) setAircraftTextInput(aircraft.toUpperCase());
  };

  /**
   * @description Handles aircraft chipdropdown click away event. Adds text input as a chip if it is available
   * @returns
   */
  const handleAirCraftClickAway = () => {
    handleAircraftChipAdd(aircraftTextInput);
  };
  // AIRCRAFT CHIPINPUT HANDLERS END

  // ALERTS MULTISELECT FILTER BEGIN
  const updateAlertFilter = (aList) => {
    if (aList == null) return;

    setAlertTypes(aList);
    let filterValue = [];
    if (msdUtils.isChecked(aList, selectAllPlaceholderText)) {
      filterValue = [AlertFilter.ALL];
    } else {
      filterValue = msdUtils.getCheckedKeyList(aList);
    }
    const newFilterData = {
      ...initialFilter,
      alerts: filterValue.length === 0 ? [AlertFilter.NO_FILTER] : filterValue,
    };
    onFilterUpdate(newFilterData);
  };
  const handleAlertCheckChange = (selectedName) => {
    if (isClearFilter) {
      handleClearFilter();
    }
    let newAlertTypes = null;
    if (selectedName === selectAllPlaceholderText) {
      if (msdUtils.isChecked(alertTypes, selectedName)) {
        newAlertTypes = msdUtils.uncheckAllItems(alertTypes);
      } else {
        newAlertTypes = msdUtils.checkAllItems(alertTypes);
      }
    } else {
      if (msdUtils.isChecked(alertTypes, selectedName)) {
        if (msdUtils.isChecked(alertTypes, selectAllPlaceholderText)) {
          var list = msdUtils.setItemSelection(alertTypes, selectAllPlaceholderText, false);
          newAlertTypes = msdUtils.setItemSelection(list, selectedName, false);
        } else {
          newAlertTypes = msdUtils.setItemSelection(alertTypes, selectedName, false);
        }
      } else {
        if (msdUtils.getCheckedCount(alertTypes) === alertTypes.length - 2) {
          newAlertTypes = msdUtils.checkAllItems(alertTypes);
        } else {
          newAlertTypes = msdUtils.setItemSelection(alertTypes, selectedName, true);
        }
      }
    }
    updateAlertFilter(newAlertTypes);
  };
  // ALERT MULTISELECT FILTER END

  /**
   * @description Returns a formatted string for a date/time based on the input parameters.
   * @param {string} date - Date and/or Time that should be formatted to match the input format.
   * @returns {string}
   */
  const formatDateTime = (date) => {
    // No date value? Nothing to return.
    if (date == null || typeof date === 'undefined') return null;
    return dayjs(date).format(formatDateOnly);
  };

  return (
    <div className="new-filter-list ">
      <Grid container spacing={3}>
        <Grid item md={3} sm={6} xs={6} data-cy="start-date-filter-container">
          <BrickContainer title={'Start Date'}>
            <DatePicker
              dataCyTag="start-date-filter"
              value={formatDateTime(initialFilter.startDate)}
              format="MMMM DD, YYYY"
              onChange={(date) => handleDatePickerSelection(date, 'startDate')}
              onKeyUp={(date) => handleDatePickerSelection(date, 'startDate')}
              isDisabled={isDisabled}
              className="filter-item start-date-picker"
              onKeyDown={handleDatePickerOnKeyDownEvent}
              isAutoFocus={true}
              {...maxDateProps} //maxDate Props
            />
          </BrickContainer>
        </Grid>
        <Grid item md={3} sm={6} xs={6} data-cy="end-date-filter-container">
          <BrickContainer title={'End Date'}>
            <DatePicker
              dataCyTag="end-date-filter"
              value={formatDateTime(initialFilter.endDate)}
              format="MMMM DD, YYYY"
              onChange={(date) => handleDatePickerSelection(date, 'endDate')}
              onKeyUp={(date) => handleDatePickerSelection(date, 'endDate')}
              isDisabled={isDisabled}
              className="filter-item end-date-picker"
              onKeyDown={handleDatePickerOnKeyDownEvent}
              {...maxDateProps} //maxDate Props
            />
          </BrickContainer>
        </Grid>
        <Grid item md={6} sm={12} xs={12}></Grid>
        <Grid item md={6} sm={12} xs={12}>
          <BrickContainer title="Origin">
            <ChipInputDropdown
              displayList={getOriginFilteredAirports}
              displayChips={initialFilter.origin}
              onSuggestionSelect={handleOriginSuggestionSelect}
              onChipAdd={handleOriginChipAdd}
              onChipDelete={handleOriginChipDelete}
              textInput={originTextInput}
              onTextChange={handleOriginTextInputChange}
              isDisabled={isDisabled}
              className="filter-item filter-origin"
              isFullWidth={true}
              isReadOnly={false}
              dataCyTag="origin-filter"
              onClickAway={handleOriginClickAway}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <BrickContainer title="Destination">
            <ChipInputDropdown
              displayList={getDestinationFilteredAirports}
              displayChips={initialFilter.destination}
              onSuggestionSelect={handleDestinationSuggestionSelect}
              onChipAdd={handleDestinationChipAdd}
              onChipDelete={handleDestinationChipDelete}
              textInput={destinationTextInput}
              onTextChange={handleDestinationTextInputChange}
              isDisabled={isDisabled}
              isFullWidth={true}
              isReadOnly={false}
              className={'filter-destination'}
              dataCyTag="destination-filter"
              onClickAway={handleDestinationClickAway}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <BrickContainer title="Aircraft Tail Number">
            <ChipInputDropdown
              displayList={filteredAircrafts}
              displayChips={initialFilter.aircraft}
              onSuggestionSelect={handleAircraftsSuggestionSelect}
              onChipAdd={handleAircraftChipAdd}
              onChipDelete={handleAircraftChipDelete}
              textInput={aircraftTextInput}
              onTextChange={handleAircraftTextChange}
              isDisabled={isDisabled}
              isFullWidth={true}
              isReadOnly={false}
              className={'filter-aircraft'}
              dataCyTag="aircraft-filter"
              onClickAway={handleAirCraftClickAway}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <BrickContainer title="Flight Number">
            <ChipInput
              displayChips={initialFilter.flight}
              onChipAdd={handleFlightNumberAdd}
              onChipDelete={handleFlightNumberDelete}
              textInput={flightNumberTextInput}
              onTextChange={handleFlightNumberTextChange}
              onTextKeyDown={handleFlightNumberTextKeyDown}
              isDisabled={isDisabled}
              isFullWidth={true}
              isReadOnly={false}
              className={'filter-flightnumber'}
              dataCyTag={'flightNumber-filter'}
              onInputBlur={handleFlightNumberBlur}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BrickContainer title="Airline">
            <MultiSelectDropdown
              displayText={getAirlineValues.placeholder}
              displayList={getAirlineValues.items}
              className="filter-item airline-filter"
              dataCyTag="airline-filter"
              isDisabled={isDisabled}
              onCheckChange={handleAirlineCheckChange}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BrickContainer title="Subfleet">
            <MultiSelectDropdown
              displayText={fleetTypeplaceholder}
              displayList={fleetTypes}
              className="filter-item subFleet-filter"
              dataCyTag="subFleet-filter"
              isDisabled={isDisabled}
              onCheckChange={handleFleetTypeCheckChange}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BrickContainer title="Alert">
            <MultiSelectDropdown
              displayText={alertTypesplaceholder}
              displayList={alertTypes}
              className="filter-item alert-filter"
              dataCyTag="alert-filter"
              isDisabled={false}
              onCheckChange={handleAlertCheckChange}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BrickContainer title="Phase of Flight">
            <MultiSelectDropdown
              displayText={getPhaseOfFlightValues.placeholder}
              displayList={getPhaseOfFlightValues.items}
              className="filter-item phase-of-flight-filter"
              dataCyTag="phase-of-flight-filter"
              isDisabled={isDisabled}
              onCheckChange={handlePhaseOfFlightCheckChange}
            />
          </BrickContainer>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BrickContainer title="Assignment">
            <MultiSelectDropdown
              displayText={assignmentListValues.placeholder}
              displayList={assignmentListValues.items}
              className="filter-item assignments-filter"
              dataCyTag="multi-assignments-filter"
              isDisabled={isDisabled}
              onCheckChange={handleAssignmentCheckChange}
            />
          </BrickContainer>
        </Grid>
      </Grid>
    </div>
  );
};

FilterContainer.propTypes = {
  initialFilter: PropTypes.object,
  isDisabled: PropTypes.bool,
  onFilterUpdate: PropTypes.func,
  aircraftList: PropTypes.array,
};

export default withAppInsightsTracking(FilterContainer);
