import { axiosInstance } from '../apiClient';

const API_ROUTE = 'userconfigurations';

/**
 * Returns the userconfigurations list from the User Configurations API
 * @returns the axios response
 */
export const getUserConfigurations = async () => {
  const apiClient = axiosInstance;
  const url = `${API_ROUTE}/api/v1/userconfigurations`;
  const response = await apiClient.get(url);
  return response.data;
};

/**
 * Returns the a configuration for an ID  from the User Configurations API
 * @returns the axios response
 */
export const getUserConfiguration = async (configurationId) => {
  const apiClient = axiosInstance;
  const url = `${API_ROUTE}/api/v1/userconfigurations/${configurationId}`;
  const response = await apiClient.get(url);
  return response.data;
};
