import { useDispatch, useSelector } from 'react-redux';
import { SummaryPanelAction } from '../../redux/actionTypes';

/**
 * Selector for the SummaryPanel store
 */
const selectSummaryPanel = (state) => state.summaryPanelReducer;

/**
 * Custom hook for retrieving the cross hair store
 * @returns selector hook for retrieving SummaryPanel store
 */
export const useSummaryPanelStore = () => {
  return useSelector(selectSummaryPanel);
};

/**
 * Custom hook for dispatching actions to update SummaryPanel flags
 */

export const useSummaryPanelDispatch = () => {
  const dispatch = useDispatch();

  // callback to update number of SummaryPanels
  const updateSummaryPanelMode = (payload) => {
    dispatch({
      type: SummaryPanelAction.UPDATE_PANEL_MODE,
      payload: { panelMode: payload.panelMode },
    });
  };

  return { updateSummaryPanelMode };
};
