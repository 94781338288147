import React, { createContext, useEffect } from 'react';
import { useRoleAssignmentContext } from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import { useFilterDispatch } from '../../hooks/useFilterStore/useFilterStore';
import { AssignmentFilter, TimeZones } from '../../lib/constants';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';

const ReduxDispatcherProviderContext = createContext(null);

/**
 * The ReduxDispatcherProvider component handles setting of the defaultAssignmentGroupTypeName & Timezone in filter store .
 * not set the value until roleAssignments and feature flags are loaded.
 * @param {Node} children - the components to be rendered.
 */
const ReduxDispatcherProvider = ({ children }) => {
  const { roleAssignments } = useRoleAssignmentContext();
  const { receivedDefaultAssignmentFilter, receivedTimeZoneSetting, receivedOperatingAirlineRole } =
    useFilterDispatch();
  const { showFeature, isReady } = useFeatureFlag();

  /**
   * Side-effect to dispatch the default assignment filter value to the filter store
   */
  useEffect(() => {
    if (
      !roleAssignments.loading &&
      roleAssignments.role != null &&
      roleAssignments.role.defaultAssignmentGroupTypeName
    ) {
      let defaultAssignment = roleAssignments.role.defaultAssignmentGroupTypeName;
      receivedOperatingAirlineRole({ operatingAirlineRole: roleAssignments.role.operatingAirline });
      if (defaultAssignment === 'FLIGHT') {
        receivedDefaultAssignmentFilter({ defaultAssignmentFilter: AssignmentFilter.MY_FLIGHTS });
      } else if (defaultAssignment === 'AIRCRAFT') {
        receivedDefaultAssignmentFilter({ defaultAssignmentFilter: AssignmentFilter.MY_AIRCRAFT });
      }
    }
  }, [roleAssignments.loading, receivedDefaultAssignmentFilter, roleAssignments.role]);

  /**
   * Side-effect to dispatch the timezone value to the filter store
   */
  useEffect(() => {
    if (isReady) {
      receivedTimeZoneSetting({ timezone: TimeZones.PDT });
    }
  }, [isReady, receivedTimeZoneSetting, showFeature, roleAssignments.role]);

  return (
    <ReduxDispatcherProviderContext.Provider
      value={
        {
          /* intentionally blank */
        }
      }
    >
      {children}
    </ReduxDispatcherProviderContext.Provider>
  );
};

export { ReduxDispatcherProvider };
