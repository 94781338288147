import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { WarningTypes } from '../../../../lib/constants';
import './TextInput.css';

const useStyles = makeStyles((theme) => ({
  rootStyle: {
    height: '4.4rem',
    borderRadius: '4px',
    '& input::placeholder': {
      position: 'absolute',
      width: '175px',
      height: '17px',
      fontFamily: 'Lato',
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  normalStyle: {
    border: '2px solid var(--lightGray)',
  },
  warningStyle: {
    border: '2px solid var(--warning-color)',
  },
  errorStyle: {
    border: '2px solid var(--error-color)',
  },
  warningLabel: {
    fontSize: '1.2rem',
    position: 'absolute',
    paddingTop: '2px',
  },
}));

/**
 * TextInput is a Controlled Component and renders text with label and able to display error
 * of HH:MM z.
 * @param {string} value - the value of this component
 * @param {string} suffixText - suffix text to be displayed as end adornment on the input component
 * @param {func} onChange - callback function when the input is changed
 * @param {func} onKeyDown - callback function when key pressed down on input control
 * @param {func} onBlur - callback function when user moves away from time input field
 * @param {enum} warningType - "error" or "warning", will drive either the red or orange outline and message
 * @param {string} warningMessage - message to display under label
 * @param {string} dataCyTag - test id attribute
 * @param {string} placeholderText - placeholderText
 * @param {number} maxLengthValue - maxLengthValue
 */
const TextInput = ({
  value,
  suffixText = '',
  onChange = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
  warningType = WarningTypes.NONE,
  warningMessage = '',
  dataCyTag = '',
  placeholderText = '',
  maxLengthValue = 0,
  autoFocus = false,
}) => {
  const classes = useStyles();

  /**
   * @description handles change and calls onChange event to parent with right parameters
   * @param {object} e onChange event object
   */
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <>
      <OutlinedInput
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        fullWidth={true}
        autoFocus={autoFocus}
        placeholder={placeholderText}
        className={`textinput ${classes.rootStyle} ${
          warningType === WarningTypes.WARNING
            ? classes.warningStyle
            : warningType === WarningTypes.ERROR
            ? classes.errorStyle
            : classes.normalStyle
        }`}
        endAdornment={<InputAdornment position="end">{suffixText}</InputAdornment>}
        inputProps={{
          'data-cy': dataCyTag + '-input',
          maxLength: maxLengthValue === 0 ? null : maxLengthValue,
        }}
        notched={false}
        data-cy={dataCyTag}
        labelWidth={0}
      />
      {warningType !== WarningTypes.NONE ? (
        <div
          data-cy={`${dataCyTag}-validation-message`}
          style={{
            color: `${
              warningType === WarningTypes.WARNING
                ? 'var(--warning-color)'
                : warningType === WarningTypes.ERROR
                ? 'var(--error-color)'
                : ''
            }`,
          }}
          className={classes.warningLabel}
        >
          {' '}
          {warningMessage}{' '}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TextInput;

TextInput.propTypes = {
  value: PropTypes.string,
  suffixText: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  warningType: PropTypes.string,
  warningMessage: PropTypes.string,
  dataCyTag: PropTypes.string,
};
