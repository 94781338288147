import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { isUrlHttpOrHttps } from '../../../lib/utils';
import { IrropsMenuItemAction } from '../../../lib/constants';

import './FlightActionMenu.css';

/**
 * @description Menu Item component for the Action menu on the Flights table.
 * @param {object} flightLeg - The Flight Leg data object.
 * @param {string] dataCyTag - A unique string for building the data-cy attribute for this menu item.
 * @param {string} text - The text to display on the page for the menu item.
 * @param {string} hrefUrl - A URL string for opening a new browser tab.
 * @param {func} onClickHandler - Function delegate from the parent component that should also be executed when this menu item is clicked.
 * @param {object} actionParams - The actionParams object contains flightLegKey.
 * @param {string} actionType - A actionType string determines actionMenuItem.
 */
const FlightActionMenuItem = ({ text, hrefUrl, actionType, actionParams, onClickHandler, dataCyTag }) => {
  /**
   * @description Constructs the unique data-cy attribute name for the menu element.
   */
  const getDataCyName = () => `actionmenuitem-${dataCyTag}-flightlegkey-${actionParams.flightLegKey}`;
  // The 'Team Messaging' menu item uses an Icon. Add other icons here if needed in the future
  const getMenuItemIcon = () => {
    switch (actionType) {
      case IrropsMenuItemAction.TEAM_MESSAGING:
        return <FontAwesomeIcon className="external-link-icon" data-cy="external-link-icon" icon={faExternalLinkAlt} />;
      default:
    }
  };
  const handleOnClick = () => {
    onClickHandler(actionType);
  };
  // Why is rel="noopener noreferrer" in the link?  React compiler warning pointed to this page:
  // https://mathiasbynens.github.io/rel-noopener/ .  It talks about security vulnerabilities of opening pages using target="_blank"
  return (
    <>
      {(actionType === IrropsMenuItemAction.OPEN_URL || actionType === IrropsMenuItemAction.TEAM_MESSAGING) &&
      hrefUrl ? (
        <a
          className="flight-list-table-action-menu-item"
          href={isUrlHttpOrHttps(hrefUrl) ? hrefUrl : null}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleOnClick}
          data-cy={getDataCyName()}
        >
          {text}
          {getMenuItemIcon()}
        </a>
      ) : (
        <div className="flight-list-table-action-menu-item" onClick={handleOnClick} data-cy={getDataCyName()}>
          {text}
        </div>
      )}
    </>
  );
};

FlightActionMenuItem.propTypes = {
  text: PropTypes.string,
  hrefUrl: PropTypes.string,
  actionType: PropTypes.string,
  actionParams: PropTypes.object,
  onClickHandler: PropTypes.func,
  dataCyTag: PropTypes.string,
};

export default withAppInsightsTracking(FlightActionMenuItem);
