import { getTransformValues } from '../../../../../lib/htmlElementUtils/htmlElementUtils';

/**
 * @description Calculate the position of the minimized view
 * @param {number} index - index of minimized view
 * @returns
 */
export const calculateMinimizedPosition = (index) => {
  const rowCapacity = Math.floor(window.innerWidth / 200); // Number of windows per row
  const row = Math.floor(index / rowCapacity);
  const col = index % rowCapacity;
  const x = 15 + col * 200; // 200px width per window, starting from 15px
  const y = window.innerHeight - (row + 1) * 35 - 120;
  return { x, y };
};
/**
 * @description Callback when minimized view dragging ends
 * @param {HTMLElement} refCurrent - reference to the view window
 * @param {number} id - view id
 * @param {boolean} isMinimized - whether the view is minimized
 * @param {function} setDraggedMiniView - function to set the dragged minimized view
 * @param {object} initialMinimizedPos - initial position of the minimized view
 * @returns
 */
export const dragEndCallback = (refCurrent, id, isMinimized, setDraggedMiniView, initialMinimizedPos) => {
  if (!isMinimized) return;
  const { translateX, translateY } = getTransformValues(refCurrent);
  const { height, width } = refCurrent.getBoundingClientRect();
  const { x: initialX, y: initialY } = initialMinimizedPos;
  if (translateY < initialY - height || translateX > initialX + width) {
    setDraggedMiniView(id);
  }
};
