import { useQueryClient } from 'react-query';
import { RefetchPageData } from '../../lib/constants';
import { useCallback } from 'react';

/**
 * Custom hook for refetching data from react-query
 * @returns {Object} - refetchData
 */
export const useRefetchPageData = () => {
  const queryClient = useQueryClient();

  /**
   *
   * @param {*} queryKey
   * @param {*} silent
   */
  const refetchData = useCallback(
    (refetchPageData, silent = true) => {
      let queryKeys = [];

      switch (refetchPageData) {
        case RefetchPageData.GANTT:
          queryKeys.push('airtrakmanager-gantt-config');
          break;
        case RefetchPageData.FLIGHT_LIST_TABLE:
          queryKeys.push('flights');
          break;
        case RefetchPageData.FLIGHT_DETAIL_PANE:
          queryKeys.push('flightdetails');
          break;
        case RefetchPageData.SUMMARY_MINI:
        case RefetchPageData.SUMMARY_FLIGHT_DETAIL:
          queryKeys.push('FlightSummary');
          queryKeys.push('flightdetails');
          queryKeys.push('FlightSummaryDetails');
          queryKeys.push('AircraftCrewMembers');
          break;
        case RefetchPageData.FLIGHT_DETAIL_PANE_ACTIVITY_LOG:
          queryKeys.push('flightOperationalNotes');
          break;
        default:
          break;
      }

      if (silent) {
        queryKeys.forEach((queryKey) => queryClient.refetchQueries(queryKey));
      } else {
        // reset cached data to set isLoading to true
        queryKeys.forEach((queryKey) => queryClient.resetQueries(queryKey));
      }
    },
    [queryClient],
  );

  const refetchDataFor = (refetchPageDataList) => {
    for (const refetchPageData of refetchPageDataList) {
      refetchData(refetchPageData);
    }
  };

  return { refetchData, refetchDataFor };
};
