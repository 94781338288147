import windowManagerReducer from './windowManagerReducer';
import rootReducer from './rootReducer';

const multipleViewsRootReducer = (state, action) => {
  const activeId = state?.viewManagerReducer?.viewOrder[0] || 0;
  const updatedWindowState = windowManagerReducer(state?.viewManagerReducer, action);
  const update = {
    ...rootReducer(state, action), // Need to keep this in until updating hooks
    viewManagerReducer: updatedWindowState,
    viewWindowData:
      updatedWindowState?.viewWindows.map((viewWindow) => {
        const currentId = viewWindow.id;
        const currentIdState = state?.viewWindowData
          ? state.viewWindowData.find((viewData) => viewData.id === currentId)
          : {};
        return { id: currentId, ...rootReducer(currentIdState, currentId === activeId ? action : { type: '' }) };
      }) || [],
  };
  return update;
};

export default multipleViewsRootReducer;
