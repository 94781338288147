import { ConfigFileAction } from '../actionTypes';
// initial state values
const initialState = {
  openSaveConfig: false,
};

/**
 * Given the previous state and action payload return the new state for config mode
 * @returns - the initial state for the config store
 */

const configReducer = (state = initialState, action) => {
  // graduate to SWITCH statement when there are more actiontypes available for this reducer
  if (action.type === ConfigFileAction.UPDATE_SAVECONFIG) {
    return {
      ...state,
      openSaveConfig: action.payload.openSaveConfig ?? initialState.openSaveConfig,
    };
  }

  return state;
};

export default configReducer;
