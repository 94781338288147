import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SummaryAircraftDetailRow from './SummaryAircraftDetailRow/SummaryAircraftDetailRow';
import { useSelectedItemStore } from '../../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { useAircraftDetails } from '../../../../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';
import { formatDateTime } from '../../../../../lib/dateTimeUtils';
import { DateFormat, AirlineFilter } from '../../../../../lib/constants';
import { getAirlineName } from '../../../../../lib/displayUtils';
import './SummaryAircraftTab.css';
import { isGroundEventPuck } from '../../../../../lib/utils';

//* SummaryAircraftTab component renders the MEL/CDL list and maintenance messages for the selected flight leg */
const SummaryAircraftTab = () => {
  const [melCdlList, setMelCdlList] = useState([]);
  const [isShowMessages, setIsShowMessages] = useState(false);

  // Get the selected flight leg from the store
  const { data: summaryFlightLeg } = useSelectedItemStore();
  const isGroundEvent = isGroundEventPuck(summaryFlightLeg?.puckType);
  const isSkywestFlight = summaryFlightLeg?.airline === getAirlineName(AirlineFilter.OO);

  // Call the useAircraftDetails hook to get the aircraft details for the selected flight leg from the AirTrakManager API
  const { isLoading: aircraftLoading, data: aircraftData } = useAircraftDetails(
    {
      airline: summaryFlightLeg?.airline,
      origin: summaryFlightLeg?.orig,
      destination: summaryFlightLeg?.dest,
      flightNumber: summaryFlightLeg?.flightNumber,
      operatingAirlineCode: summaryFlightLeg?.operatingAirlineCode,
      departureCount: summaryFlightLeg?.departureCount,
      scheduledOperatingDate: formatDateTime(summaryFlightLeg?.departureDate, DateFormat.DEFAULT),
      aircraft: summaryFlightLeg.aircraft,
    },
    isGroundEvent,
    !isSkywestFlight,
  );

  // Update MEL/CDL list when aircraft data is loaded
  useEffect(() => {
    if (!aircraftLoading && aircraftData) {
      const { aircraftDetails, errors } = aircraftData;
      // Set the MEL/CDL list if the aircraft details exist
      if (aircraftDetails) {
        setMelCdlList(aircraftDetails.melCdlList);
      }

      // Show the error message if the last error is MELData
      const hasMelDataError = errors?.length > 0 && errors[errors.length - 1].errorName === 'MELData';
      setIsShowMessages(hasMelDataError);
    }
  }, [aircraftLoading, aircraftData, summaryFlightLeg]);

  const maintenanceMessages = [];
  return (
    <div className="summary-aircraft-tab-container" data-cy="summary-aircraft-tab-container">
      <div className="aircraft-tab-container-row">
        {!isSkywestFlight ? (
          <>
            <Grid container>
              <SummaryAircraftDetailRow
                aircraftDetails={melCdlList}
                station={summaryFlightLeg?.orig}
                isMelCDRow={true}
                isLoading={aircraftLoading}
                isShowMessages={isShowMessages}
              />
            </Grid>

            <Grid container>
              <SummaryAircraftDetailRow
                aircraftDetails={maintenanceMessages}
                station={summaryFlightLeg?.orig}
                isMelCDRow={false}
                isShowMessages={false}
              />
            </Grid>
          </>
        ) : (
          <div data-cy="error-message-skywest-flight" className="no-mels-cdls-exist-aircraft">
            {'No Maintenance data is shown for Skywest flights.'}
          </div>
        )}
      </div>
    </div>
  );
};

export default withAppInsightsTracking(SummaryAircraftTab);
